gaerdvark.models.Game = (function(module) {
	
	/**
	 * Constants to represent the different statii that a question can have
	 */
	module.STATUS_CORRECT = 'correct';
	module.STATUS_INCORRECT = 'incorrect';
	module.STATUS_CURRENT = 'current';
	module.STATUS_INCOMPLETE = 'incomplete';
	
	return module;
	
})(gaerdvark.models.Game || {});