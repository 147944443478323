gaerdvark.models.Game = (function(module) {
	
	/**
	 * Base Game Scoring object that will score a game given the scoring settings of the game.
	 * @param {gaerdvark.tools.BaseGame.ScoringSettings}
	 */
	function Scorer(settings) {
		
		/**
		 * Calculates a GameScore with it's points and stars and returns it.
		 * @param {Number} accuracy Accuracy is a number from 0 to 100
		 * @param {Number} userTime is the time in milliseconds the game took
		 */
		this.calculateScore = function(accuracy, userTime) {
			
			var stars = 0;
			var points = 0;
			accuracy = +accuracy;
			userTime = +userTime;
			
			if (isNaN(accuracy) || accuracy < 0 || accuracy > 100) {
				throwError("Invalid format for accuracy.  Must be a valid number in the range 0-100");
				return;
			}
			
			if (userTime < 0 || isNaN(userTime))
			{
				throwError("Invalid total time must be a valid positive number");
				return;
			}
			
			accuracy /= 100; // convert to a percentage
			var userTimeInSeconds = userTime / 1000; // convert from milliseconds to seconds
			
			
			var pointsAmplitude = settings.amplitude;
			var accuracyWeighting = settings.timeToAccuracyWeighting / 100; // convert to percent
			var fastestTime = settings.fastestTime;
			
			// Algorithm Allen came up with was the following
			// points = amplitude * accuracy - (userTime - fastestTime) * timeAccuracyWeighting
			points = pointsAmplitude * accuracy
					- (userTimeInSeconds - fastestTime) * accuracyWeighting;
			
			points = Math.floor(points); // truncate any decimals
			
			// don't let the points go below 0
			if (points < 0)
			{
				points = 0;
			}
			
			if (accuracy < 1)
			{
				stars = 0;
			}
			else
			{
				stars = settings.getStarsForTime(userTimeInSeconds);
				
			}
			
			var gameScore = new module.GameScore(points, stars, userTime);
			gameScore.maxStars = settings.getMaxStars();
			
			return gameScore;
		}
	}
	
	module.Scorer = Scorer;
	return module;
})(gaerdvark.models.Game || {});