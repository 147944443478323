/* global gaerdvark */
if(!gaerdvark.models.ckeditor){
	gaerdvark.models.ckeditor = {};
}

gaerdvark.models.ckeditor.PrintButton = function (gaerdvark){
	var utils = gaerdvark.utils,
	PRINT_DIV_CLASS = "printDiv";
	
	function letThemKnow(){
		alert("You have selected a location where there is already a printable section.");
	}
	
	var create = function (editor, toolname){
		var selectedElement = gaerdvark.models.ckeditor.CkEditorUtils.getSelectedElement(editor, 'div');
		if (selectedElement && selectedElement.getAttribute("data-printNode")){
			letThemKnow();
			return;
		}
		
		//Check the begining and end of the range and make sure they have the same parent. If they
		// don't then ignore it.
		var ranges = editor.getSelection().getRanges();
		for(var i = 0; i < ranges.length; i++){
			ranges[i].optimize();
			var startContainer = ranges[i].startContainer;
			var endContainer = ranges[i].endContainer;

			//checking to see if I should ignore it. I need to ignore it if we are already in a wordTyperSpan.
			if(isPrintButtonDiv(startContainer) || isPrintButtonDiv(endContainer)){
				letThemKnow();
				return;
			}

			if( hasPrintButtonParent(startContainer) ){
				letThemKnow();
				return;
			}

			if( hasPrintButtonParent(endContainer) ){
				letThemKnow();
				return;
			}
		}
		
		var fragment = ranges[0].extractContents();
		var div = new CKEDITOR.dom.element("div");
		
		div.setAttributes({
				"class":PRINT_DIV_CLASS
				,"id":utils.getUniqueNodeId()
				,"data-printNode":true
		});
		
		fragment.appendTo(div);
		
		ranges[0].collapse();
		ranges[0].select();
		editor.insertElement(div);
				
		var button = new CKEDITOR.dom.element('button');
		button.addClass("printButton");
		button.setAttribute( "onclick", "gaerdvark.models.ckeditor.PrintButton.print(this)");
		button.setText("Print");
		button.insertAfter(div);
	}
	/**
	 * checks if an element is a word typer span.
	 * @param element
	 * @returns {Boolean}
	 */
	function isPrintButtonDiv(element){
		if(element.type == CKEDITOR.NODE_TEXT){
			return false;
		}
		return element.hasAttribute("data-printNode") && element.getAttribute("data-printNode");
	}
	/**
	 * recursively checks parents to see if we are in a word typer span.
	 * this shouldn't loops more than once.
	 * @param element
	 * @returns
	 */
	function hasPrintButtonParent(element){

		if(!element){
			return false;
		}

		var parent = element.getParent();
		if(element.type == CKEDITOR.NODE_TEXT){
			return hasPrintButtonParent(parent);
		}

		if(element.getName() == "body"){
			return false;
		}

		if(!parent){
			return false;
		}

		if(isPrintButtonDiv(element)){
			return true;
		}else{
			return hasPrintButtonParent(parent);
		}

		return false;
	}
	
	function print(button){
		var commonParent = utils.getAncesterBySelector(button, ".mainPageContainer");
		var classNameElements = commonParent.getElementsByClassName(PRINT_DIV_CLASS);
		if(classNameElements.length > 1){
			alert("There is more than one print section found on this page. I don't know which one to print, so... I am going to print the first one.");
		}
		
		var nodeToPrint = classNameElements[0];
		utils.Print.printNode(nodeToPrint, {"title":"",styles:""});
	}
	return {
		"create":create
		,"print":print
	};
	
}(gaerdvark);