/* global jQuery */
gaerdvark.models.Game = (function(module, models, $) {

	/**
	 * Default audio text if an audio question does not have any display text.
	 */
	var AUDIO_DEFAULT_DISPLAY_TEXT = "Listen to the question";
	
	/**
	 * Helper object that represents the state / actions / behaviors of the user when they are looking at the 'game' interactive piece
	 * of this game.
	 * 
	 * @param {object} initData data to initialize the view
	 * @param {Game} game the game model that this view uses.
	 * @param {string} The html of the view.
	 * @param {object} Hash map of function callbacks the view can call.
	 * @param {object} Hash map of the progress icons
	 * @returns {SoundMatchGame.GameView}
	 */
	function ThisOrThatGameView(initData, game, html, actions, progressIcons)
	{
		var gameView = new gaerdvark.models.Game.GameView(initData, game, html, actions, progressIcons);
		
		// as we need to do our own postload we will bind here.
		var parentPostLoad = gameView.postLoad.bind(gameView);
		
		/**
		 * The questions container where the question text will go.
		 */
		gameView.questionContainer = new models.Container("questionTextContainer");
		
		/**
		 * Given a progress element it will update the DOM node with the correct icon / timestamp for that progress
		 * @param {object} progress object
		 */
		gameView.updateProgress = function(progress) {
			var element = document.getElementById('progress-' + progress.id);
			element.src = progress.icon;
		};
		
		/**
		 * The view has been loaded, now we need to run our base game post load, and display our question text.
		 * We also need to bind to the timer so we can display the updated time. 
		 */
		gameView.postLoad = function() {
			parentPostLoad();
			gameView.displayQuestion();
			game.registerTimerCallback(this.updateTimer.bind(this));
		};
		
		/**
		 * Updates the timer countdown on the this or that game
		 * @param {gaerdvark.utils.GaeTimer} the timer object that we will use to update our time.
		 */
		gameView.updateTimer = function(timer) {
			var timerElement = document.getElementById('timeDisplay' + game.getId());
			if (timerElement) {
				timerElement.innerHTML = timer.getTimeRemainingInSeconds();
			} 
		};
		
		/**
		 * Visually shows which answer was correct and which was incorrect
		 */
		gameView.showAnswers = function() {
			var question = game.currentQuestion;
			var choices = game.getCurrentChoices();
			if (question && choices) {
				choices.forEach(function(choice) {
					var $choice = $("#choice-" + choice.id).removeClass("correct incorrect");
					if (choice.id === question.correctAnswer.id) {
						$choice.addClass("correct");
					}
					else {
						$choice.addClass("incorrect");
					}
				});
			}
		};
		
		/**
		 * Displays our question text on the screen.  If the question has no question text it displays a default message
		 * if it's an audio question.
		 */
		gameView.displayQuestion = function() {
			// show the question text if we are dealing with a text question
			var currentQuestion = game.currentQuestion;
			if (!currentQuestion) {
				throwError("Asked to display a question when there is no currentQuestin");
				return;
			} 
			
			var displayText = currentQuestion.content;
			if (!displayText && currentQuestion.audio) {
				displayText = AUDIO_DEFAULT_DISPLAY_TEXT;
			}
			this.loadContainer(gameView.questionContainer, {displayText: displayText}, 'question');
		};

		return gameView;
	}
	module.ThisOrThatGameView = ThisOrThatGameView;
	
	return module;
})(gaerdvark.models.Game || {}, gaerdvark.models || {}, jQuery);