/* global jQuery */
gaerdvark.models.Game = (function(module, models, $) {

	/**
	 * Helper object that represents the state / actions / behaviors of the user when they are looking at the 'game' interactive piece
	 * of this game.
	 * 
	 * @param {object} initData data to initialize the view
	 * @param {Game} game the game model that this view uses.
	 * @param {string} The html of the view.
	 * @param {object} Hash map of function callbacks the view can call.
	 * @param {object} Hash map of the progress icons
	 * @returns {SoundMatchGame.GameView}
	 */
	function GameView(initData, game, html, actions, progressIcons)
	{
		/**
		 * Flag that keeps track of whether we are doing any animations such as sliding the choices across the screen.
		 * This is used to know if we need to disable user input or not.
		 * {boolean}
		 */
		var executingAnimation = false;

		/**
		 * pointer to keep track of ourselves.
		 */
		var _view = this;

		/**
		 * Maps progress ids to states.  Allows the progress to be updated if anything changes.
		 */
		var progressMap = null;

		/**
		 * Array list of the progress ids so we can create them in order.
		 */
		var progressList = [];

		

		/**
		 * Given a list of progress objects it will create the progressMap hashmap as well as populating all of the icons
		 * for the progress.  progressMap will be populated and progressList will be populated by the end of the function.
		 * @param {object} list
		 */
		function generateProgressData(list)
		{
			progressMap = {};

			gaerdvark.utils.forEachArray(list, function(progress) {
				progressMap[progress.id] = progress;
				progressMap[progress.id].icon = progressIcons.getIconForStatus(progress.status);

				progressList.push(progressMap[progress.id]);
			});
		}

		/**
		 * Given a list of progress objects find the ones that the icons have changed
		 * so we can update them accordingly
		 * @param {array} progressList
		 */
		function getUpdatedProgressList(progressList)
		{
			var updatedProgressList = gaerdvark.utils.filterArray(progressList, function(progress) {
				if (progressMap.hasOwnProperty(progress.id))
				{
					return progressMap[progress.id].status !== progress.status;
				}
				else
				{
					throwError("Progress not found in progress map " + progress.id);
					return;
				}
			});

			// with the new list update our progress
			gaerdvark.utils.forEachArray(updatedProgressList, function(progress) {
				progressMap[progress.id] = progress;
				progressMap[progress.id].icon = progressIcons.getIconForStatus(progress.status);
			});

			return updatedProgressList;
		}
		
		/**
		 * Given a node it adds the select answer handler to the node.  This works across mobile and desktop.
		 * @param {DOMNode} node
		 */
		function addSelectAnswerClickHandler(node) {
			$(node).find(".choice").on("vclick", function(evt) {
				// don't trigger the mouse click because we are moving content
				// when you move content it can trigger another click on whatever is under the content
				// 300 ms away
				evt.preventDefault(); 
				actions.selectAnswer(evt, $(this).data("choice"));
			});
		}

		/**
		 * The node that contains this all of the html for this view.
		 */
		this.node = null;

		/**
		 * Process html object that was used to produce this view.
		 * {ProcessHtml}
		 */
		this.htmlProcessor = null;

		/**
		 * The object representing the progress container
		 * @param {Container}
		 */
		this.progressContainer = new models.Container('progressContainer');

		/**
		 * The object representing the answers container where all of the user choices are displayed.
		 * @param {Container}
		 */
		this.answersContainer = new models.Container('answersContainer');

		/**
		 * The background image for this view
		 * @param {GameView}
		 */
		this.background = new module.GameImage('', 'background');

		/**
		 * The class to use in displaying the time.
		 * @param {string}
		 */
		this.timeClass = '';
		
		this.id = '';

		// if we have data to populate use that data.
		if (initData)
		{
			this.progressContainer = new models.Container('progressContainer', initData.progressContainer);
			this.answersContainer = new models.Container('answersContainer', initData.answersContainer);
			this.background = new module.GameImage(initData.background, 'background');
			this.timeClass = initData.timeClass ? initData.timeClass : '';
			this.id = game.getId();
		}

		/**
		 * Function that lets the controller know we are currently animating the view and not ready to process
		 * another instruction
		 */
		this.inAnimation = function() {
			return executingAnimation;
		};

		/**
		 * Used when preloading the image assets.  It returns all of the images to preload, which in this case is the background and progress icons.
		 * @return {array} of GameImage objects
		 */
		this.getImagesToLoad = function() {

			var imagesToPreload = [this.background];
			var progressImages = progressIcons.getImagesToLoad();
			
			imagesToPreload = imagesToPreload.concat(progressImages);
			return imagesToPreload;
		};

		/**
		 * Given a progress element it will update the DOM node with the correct icon / timestamp for that progress
		 * @param {object} progress object
		 */
		this.updateProgress = function(progress) {
			var element = document.getElementById('progress-' + progress.id);
			element.src = progress.icon;

			var parentNode = element.parentNode;

			// if it's correct add the timestamp
			if (progress.status === module.STATUS_CORRECT)
			{
				var formattedTime = progress.time.getFormattedTimeInSeconds(1);
				var timeClass = this.timeClass;
				var data = {
						time: formattedTime
						,timeClass: timeClass
				};
				// put in the timestamp
				this.htmlProcessor.dynamicOn.get("timestamp", data, parentNode);
			}
		};


		/**
		 * Retrieves the node where the audio DOM nodes should be placed.
		 * @param {DOMHTMLElement}
		 */
		this.getPlacementLocationForAudio = function() {
			return document.getElementById('soundPlayer' + game.getId());
		};

		/**
		 * Displays the progress icons / timestamps on the DOM.  If this is the first time the function is called it renders all of the icons
		 * Subsequent calls only updates the progress items that have changed.
		 */
		this.displayProgress = function() {

			var currentProgressList = game.getCurrentProgress();;

			// display the container for the first time.
			if (progressMap === null)
			{
				generateProgressData(currentProgressList);

				var data = { progress: this.progressContainer
						 ,items: progressList, timeClass: this.timeClass};
				this.loadContainer(this.progressContainer, data, 'progress');
			}
			else
			{
				var updatedList = getUpdatedProgressList(currentProgressList);
				gaerdvark.utils.forEachArray(updatedList, function(progress) {
					_view.updateProgress(progress);
				});
			}
		};

		/**
		 * Renders the choices for a given question onto the screen.  The first time this is called it renders it directly onto the screen,
		 * subsequent calls will use an animation to clear the choices off the screen.  During the animation user selection of a choice
		 * is disabled until the animation has completed.
		 */
		this.displayChoices = function() {

			var container = this.answersContainer;
			var data = null;

			if (container.node !== null)
			{
				executingAnimation = true;

				// we start the new choices hidden to the far right of our container.  We then slide those answers from the right to
				// their correct position.  At the same time we slide the old answers off the screen from their position to the far left

				// start the new answers to the far right and have them slide to their correct position.
				var clonedContainer = gaerdvark.utils.cloneObject(container, true);
				data = { answers: clonedContainer
						 ,choices: game.getCurrentChoices()};
				this.loadContainer(clonedContainer, data, 'answers');
				addSelectAnswerClickHandler(clonedContainer.node);
				$(clonedContainer.node).hide(); // hide this node so we can then fade it in.
				
				$(container.node).fadeOut('fast', function() {
					$(clonedContainer.node).fadeIn('fast', function() {
						$(container.node).remove(); // remove should remove all event handlers including child event handlers
						container.node = clonedContainer.node;
						executingAnimation = false;
					});
				});
			}
			else
			{
				data = { answers: container
						 ,choices: game.getCurrentChoices()};
				this.loadContainer(container, data, 'answers');
				addSelectAnswerClickHandler(container.node);
			}
		};
		
		/**
		 * Given an container object it will do a dynamic on  to load the data.
		 * @param {Container} container
		 * @param {object} data
		 * @param {string} name
		 */
		this.loadContainer = function (container, data, name) {
			var dynamicOn = this.htmlProcessor.dynamicOn.get(name, data);
			if (dynamicOn && gaerdvark.utils.isArray(dynamicOn))
			{
				container.node = dynamicOn[0].value;
			}
		};
		
		/**
		 * Return the html that represents this view
		 * @return {string}
		 */
		this.getHtml = function() {
			return html;
		};

		/**
		 * Return the data to be used in ProcessHtml for the data flag replacements
		 * @return {object}
		 */
		this.getData = function() {
			return this;
		};
		
		
		/**
		 * Do our post load processing now that ProcessHtml has executed.  Place the progress, choices on the screen.
		 * Tell the game it can start playing the audio.
		 */
		this.postLoad = function() {

			
			this.displayProgress();

			this.displayChoices();
		};
	}
	module.GameView = GameView;
	
	return module;
})(gaerdvark.models.Game || {}, gaerdvark.models || {}, jQuery);