/**
	 * Trophy Activity Instance used to represent a collection
	 * of trophies for an activity
	 * @returns {gaerdvark.models.TrophyActivityInstance}
	 */
gaerdvark.models.TrophyActivityInstance = function(id) {
		this.trophyInstanceId = id;
		
		/**
		 * Array of {gaerdvark.models.Trophy}
		 */
		this.trophies = [];
};