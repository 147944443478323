/* global gaerdvark */
gaerdvark.models.CodingScenario = function(){
    var me = this;
    me.difficulties = {
        "EASY":"easy"
		,"MEDIUM":"medium"
		,"DIFFICULT":"difficult"
    };
    
    me.types = {
        "PHYSICIAN":"PHYSICIAN"
		,"FACILITY":"FACILITY"
    };
    
    me.encounterTypes = {
        "EVALUATION_AND_MANAGEMENT":"Evaluation & Management",
        "EVALUATION_AND_MANAGEMENT_95":"Evaluation & Management (95)",
        "EVALUATION_AND_MANAGEMENT_97":"Evaluation & Management (97)",
        "SURGERY":"Surgery",
		"IN_OFFICE":"In Office",
		"INPATIENT":"Inpatient",
		"OBSERVATIONS":"Observations",
		"EMERGENCY_MEDICINE":"Emergency Medicine",
        "ANCILLARY":"Ancillary",
        "INTERVENTIONAL":"Interventional",
        "NUCMED":"Nuc-med",
        "DIAGNOSTIC":"Diagnostic"
    };

    me.presentOnAdmissionValues = {
        "YES":"Yes, Present on Admission"
        ,"NO":"No, not present on Admission"
        ,"UNKNOWN":"Unknown if Present on Admission"
        ,"UNDETERMINED":"Clinically Undetermined"
        ,"EXEMPT":"Exempt from POA reporting"
    };

    me.statuses = {
        "RAW":"Raw"
		,"CROWDSOURCEREADY":"Crowd Source Ready"
		,"CROWDSOURCEINPROGRESS":"Crowd Sourcing In Progress"
		,"KEYREVIEW":"Key Review"
		,"PUBLISHED":"Published"
		,"REVIEW":"Review" //Something is wrong. stick it in review and fix problems and republish. 
		,"RETIRED":"Retired"
    };

    me.dischargeDispositionValues = [
        {"code":"01"
         ,"name":"Discharge to Home or Self Care (Routine Discharge)"
        }
        ,{"code":"02"
         ,"name":"Discharged/Transferred to a Short-term General Hospital for Inpatient Care"
        }
        ,{"code":"03"
         ,"name":"Discharged/Transferred to a Skilled Nursing Facility (SNF)"
        }
        ,{"code":"04"
         ,"name":"Discharged/Transferred to an Intermediate Care Facility (ICF)"
        }
        ,{"code":"05"
         ,"name":"Discharged/Transferred to a Designated Cancer Center or Children’s Hospital"
        }
        ,{"code":"06"
         ,"name":"Discharged/Transferred to Home Under Care of Organized Home Health Service Organization"
        }
        ,{"code":"07"
         ,"name":"Left Against Medical Advice or Discontinued Care"
        }
        ,{"code":"09"
         ,"name":"Admitted as an Inpatient to this Hospital"
        }
        ,{"code":"20"
         ,"name":"Expired"
        }
        ,{"code":"30"
         ,"name":"Still Patient or Expected to Return for Outpatient Services"
        }
        ,{"code":"43"
         ,"name":"Discharged/Transferred to a Federal Hospital or VA Facility"
        }
        ,{"code":"50"
         ,"name":"Discharged /Transferred to a Hospice (in-home level)"
        }
        ,{"code":"51"
         ,"name":"Discharged /Transferred to a Hospice  (inpatient level)"
        }
        ,{"code":"61"
         ,"name":"Discharged/Transferred to a Hospital-based Medicare Approved Swing Bed"
        }
        ,{"code":"62"
         ,"name":"Discharged/Transferred to an Inpatient Rehabilitation Facility"
        }
        ,{"code":"63"
         ,"name":"Discharged/Transferred to Long Term Care Hospitals"
        }
        ,{"code":"64"
         ,"name":"Discharged/Transferred to a Nursing Facility Certified Under Medicaid but not Certified Under Medicare"
        }
        ,{"code":"65"
         ,"name":"Discharged/Transferred to a Psychiatric Hospital /Unit"
        }
        ,{"code":"66"
         ,"name":"Discharged/Transferred to a Critical Access Hospital (CAH)"
        }
        ,{"code":"69"
         ,"name":"Discharge/transfer to a Designated Disaster Alternative Care Site"
        }
        ,{"code":"70"
         ,"name":"Discharged/transferred to another Type of Health Care Institution"
        }
    ];
    
    me.encounterType = "";
    me.dateCreated = new Date();
    me.medicalDocuments = [new MedicalDocument()];
    me.categories = [];
    me.instructions = "";
    me.difficulty = me.difficulties.EASY;
    me.type = me.types.PHYSICIAN;
    me.status = me.statuses.RAW;
    me.id = null;
    me.userViewId = null;
    me.codes = [];
    me.notes = "";
    me.medicalSpecialty = "";
    me.lastEdited = null;
    me.lastEditedUser = {
        "firstName":""
        ,"lastName":""
    };
    
    me.answerKeys = [answerKey()];
    
    me.addNewAnswerKey = function (){
        var ansKey = answerKey();
        me.answerKeys.push(ansKey);
        return ansKey;
    }
    
    function answerKey(){
        return {
            "admittingdx":[]//facility only
            ,"clarificationRequired":false//facilityOnly
            ,"icdPrimaryCodes":[]
            ,"startDate":new Date()
            ,"icdSecondaryCodes":[]
            ,"cptCodes":[]
            ,"id":gaerdvark.utils.getUniqueNodeId()
            ,"walkthrough":""
            ,"annualGuidelineYears":{
                "diagnostic":new Date().getFullYear()
                ,"procedural":new Date().getFullYear()
            }
            ,"dischargeDisposition":""//facility only
        };
    };
    
    me.answerKey = answerKey;
    me.MedicalDocument = function (){
        return new MedicalDocument();
    };
    
    function MedicalDocument(){
        this.content = "";
        this.title = "";
        this.id = gaerdvark.utils.getUniqueNodeId();
    }
    
    function translateToServer(){
        return {
            "dateCreated":me.dateCreated
            ,"medicalDocuments":me.medicalDocuments
            ,"codes":me.codes
            ,"notes":me.notes
            ,"status":me.status
            ,"id":me.id
            ,"type":me.type
            ,"difficulty":me.difficulty.toUpperCase()
            ,"medicalSpecialty":me.medicalSpecialty
            ,"data":{
                "answerKeys":me.answerKeys
            }
            ,"userViewId":me.userViewId
            ,"categories":me.categories
            ,"encounterType":me.encounterType
            ,"instructions":me.instructions
        };
    }
    
    function translateFromServer(scenario){
        for(var prop in scenario){
            if(me.hasOwnProperty(prop)){
                me[prop] = scenario[prop];
            }
        }
    }

    function getCategoriesString(){
        if(me.categories.length > 0){
            if(me.categories[0].hasOwnProperty("name")){
                var retArray = [];
                for(var i = 0; i < me.categories.length; i++){
                    retArray.push(me.categories[i].name);
                }
                return retArray.join(" ,");
            }
            return me.categories.join(" ,");
        }
        return "";
    }

    me.translateToServer = translateToServer;
    me.getCategoriesString = getCategoriesString;
    me.translateFromServer = translateFromServer;
};