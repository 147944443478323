gaerdvark.models.Game = (function(module, models) {
	
	/**
	 * Represents the data for the instructions screen.
	 */
	function InstructionsScreenModel() {
		
		/**
		 * The unique identifier for the instructions
		 */
		this.id = null;
		
		/**
		 * The URL to the background image
		 */
		this.background = null;
		
		/**
		 * The object representation of the image.
		 * @param {GameImage}
		 */
		this.backgroundImage = null;
		
		/**
		 * @param {AudioFile}
		 */
		this.backgroundAudio = null;
		
		/**
		 * Whether to immediately start playing the audio
		 * when the audio is loaded.
		 */
		this.backgroundAudioAutoPlay = true;
	}
	
	module.InstructionsScreenModel = InstructionsScreenModel;
	
	return module;
})(gaerdvark.models.Game || {});	