gaerdvark.models.TestEditors = (function(module, utils) {
	
	/**
	 * Represents a True False question in the system. Inherits from MultipleChoiceQuestion
	 * 
	 * @param {type} data
	 * @returns {_L1.AudioImageMultipleChoiceQuestion}
	 */
	function AudioImageMultipleChoiceQuestion(data) {
		if (!data) {
			data = {};
		}
		
		data.type = (data && utils.isValidString(data.type)) ? data.type : "Audio Image Multiple Choice";
		data.checkboxRadio = (data && gaerdvark.utils.isValidString(data.checkboxRadio)) ? data.checkboxRadio : "radio";
		data.displayDirection = (data && utils.isValidString(data.displayDirection)) ? data.displayDirection : "vertical";
		
		var question = new module.MultipleChoiceQuestion(data);
		
		var parentGetData = question.getData.bind(question);
		
		question.audioData = (data && !utils.isEmpty(data.audioData)) ? data.audioData : null;
		question.imageUrl = (data && !utils.isEmpty(data.imageUrl)) ? data.imageUrl : null;;
		
		question.getData = function() {
			var data = parentGetData();
			data.imageUrl = question.imageUrl;
			data.audioData = question.audioData;
			return data;
		}; 
		
		return question;
	}
		
	module.AudioImageMultipleChoiceQuestion = AudioImageMultipleChoiceQuestion;
	
	return module;
})(gaerdvark.models.TestEditors || {}, gaerdvark.utils);