gaerdvark.models.Game = (function(module) {
	
	/**
	 * Responsible for preloading assets before a game starts.  Will grab all of the assets for a game and fire off  a callback
	 * once all of the assets have loaded.
	 */
	function AssetsPreloader() {
		
		/**
		 * Function that will prefetch all of the assets that are needed to provide a fast response to the user and be able to start the game
		 * @param {array} views of view objects.
		 * @param {Game} gameData the game model
		 * @param {function} allAssetsLoadedCallback callback to execute once all of the assets have loaded.
		 */
		this.preloadAssets = function(views, gameData, allAssetsLoadedCallback)
		{
			var imagesLoaded = false;
			var audiosLoaded = false;

			var callAssetsLoadedCallback = function() {
				if (imagesLoaded && audiosLoaded)
				{
					allAssetsLoadedCallback();
				}
			};
			// preload the images

			var images = [];
			gaerdvark.utils.forEachArray(Object.keys(views), function(viewName) {
				if (views[viewName].getImagesToLoad) {
					images = images.concat(views[viewName].getImagesToLoad());
				}
			});

			gaerdvark.utils.preloadImages(images, function() {
				imagesLoaded = true;
				callAssetsLoadedCallback();
			});

			// preload the audio files
			gameData.preloadAudios(function() {
				audiosLoaded = true;
				callAssetsLoadedCallback();
			});
		}
	}
	
	module.AssetsPreloader = AssetsPreloader;
	
	return module;
})(gaerdvark.models.Game || {});