gaerdvark.models.Game = (function(module) {
	
	/**
	 * Represents the game model for the This Or That Game.  Extends {gaerdvark.models.Game}
	 * @see {gaerdvark.models.Game}
	 * @param {string} the id of the game if we are replaying a game.
	 */
	function ThisOrThatGameModel(id) {
		
		/**
		 * Pointer to our parent object
		 */
		var game = new module.Game(id);
		
		/**
		 * Pointer to our parent's preloadAudios method so we can override it.
		 */
		var parentPreloadAudios = game.preloadAudios.bind(game);
		
		/**
		 * Pointer to our parent's cleanup method so we can override it.
		 */
		var parentCleanUp = game.cleanup.bind(game);
		
		/**
		 * Pointer to our validation method so we can override it.
		 */
		var parentIsGameValid = game.isGameValid.bind(game);
		
		/**
		 * Array of our audio tools that we have loaded so we can clean them up.
		 */
		var loadedAudioTools = [];
		
		/**
		 * number of seconds for the timer to countdown
		 */
		var GAME_TIME = 30;
		
		/**
		 * The base number of points a user earns when they answer a question
		 */
		var POINTS_PER_QUESTION = 1000;
		
		/**
		 * The tool for playing our correct audio when a user selects the correct answer.
		 * @param {Multimedia}
		 */
		var audioCorrectTool = null;
		
		/**
		 * The tool for playing our incorrect audio when a user selects the incorrect answer.
		 * @param {Multimedia}
		 */
		var audioIncorrectTool = null;
		
		/**
		 * The tool for playing the background music during the actual game.
		 * @param {Multimedia}
		 */
		var backgroundMusicTool = null;
		
		/**
		 * The tool for playing the instructions music before the game starts.
		 * @param {Multimedia}
		 */
		var instructionsBackgroundMusicTool = null;
		
		/**
		 * The currently playing answer audio.
		 * @param {Multimedia}
		 */
		var currentAnswerAudioTool = null;
		
		/**
		 * As our game results get's called a couple of times, we cache it.
		 */
		var endGameResult = null;
		
		/**
		 * Tracks the consecutive number of times a user has marked a correct answer.  Two answers right in a row makes this two. If a user
		 * gets an answer wrong this resets back to 1.
		 */
		var consecutiveNumberOfCorrectAnswers = 1;
		
		/**
		 * Functions to execute when the timer hits the one second mark.
		 */
		var timerCallbacks = [];
		
		/**
		 * Given the number of concurrent answers a user has gotten correct, return
		 * the score that should be used for the amount of points a user
		 * earned for that answer.
		 */
		function getScore(concurrentAnswerCount) {
			var bonuses = [1, 1.5, 2.5, 3.5, 5, 6.5, 10];
			concurrentAnswerCount = +concurrentAnswerCount;
			
			if (concurrentAnswerCount > bonuses.length) {
				return bonuses[bonuses.length-1];
			}
			else if (concurrentAnswerCount < 1) {
				throwError(concurrentAnswerCount + " is not a valid range between 1 and " + multipliers.length);
				return 1; // this prevents errors
			}
			
			var bonus = bonuses[concurrentAnswerCount - 1];
			return bonus * POINTS_PER_QUESTION;
		}
		
		/**
		 * Loads up all of the ThisOrThat audio assets and calls the loadedCallback when it's complete.
		 */
		var loadAudios = function(game, loadedCallback) {
			
			var audiosToLoad = [];
			var callBacks = [];
			
			// only do the callback once everyone is loaded
			var filterCheck = function(audioTool) {
				// if our game is cleaned up... we should clear out the audio and
				// then do nothing else as the callback is left over from our cleanup process.
				if (game.isGameCleanedUp()) {
					audioTool.destruct();
					return;
				}
				
				// we keep track of these so we can clean them up at the end.
				loadedAudioTools.push(audioTool);
				var isLoaded = audiosToLoad.every(function(audio) {
					return audio.loaded;
				});
				
				if (isLoaded) {
					loadedCallback();
				}
			};
			
			if (game.correctAnswerAudio) {
				audiosToLoad.push(game.correctAnswerAudio);
				callBacks.push(game.loadAudio.bind(game, game.correctAnswerAudio, function(audioTool) {
					audioCorrectTool = audioTool;
					filterCheck(audioTool);
				}));
			}
			if (game.incorrectAnswerAudio) {
				audiosToLoad.push(game.incorrectAnswerAudio);
				callBacks.push(game.loadAudio.bind(game, game.incorrectAnswerAudio, function(audioTool) {
					audioIncorrectTool = audioTool;
					filterCheck(audioTool);
				}));
			}
			
			if (game.instructionsBackgroundAudio) {
				audiosToLoad.push(game.instructionsBackgroundAudio);
				callBacks.push(game.loadAudio.bind(game, game.instructionsBackgroundAudio, function(audioTool) {
					instructionsBackgroundMusicTool = audioTool;
					filterCheck(audioTool);
				}));
			}
			
			if (game.backgroundAudio) {
				audiosToLoad.push(game.backgroundAudio);
				callBacks.push(game.loadAudio.bind(game, game.backgroundAudio, function(audioTool) {
					backgroundMusicTool = audioTool;
					filterCheck(audioTool);
				}));
			}
			
			if (callBacks.length) {
				callBacks.forEach(function(callback) { callback(); });
			}
			else {
				throwError("there were no callbacks to call, when there should have been in the data");
				loadedCallback();
			}
		};
		
		/**
		 * When an audio finishes playing we need to set our state that we are
		 * not playing the audio anymore as well as callback anything waiting 
		 * for the audio to finish playing.
		 */
		function handlePlayAnswerAudioFinished(callBack) {
			return function() {
				currentAnswerAudioTool = null;
				callBack();
			}
		}
		
		/**
		 * Helper function that will call of our timer callbacks with the given timer.  Allows various users
		 * of the game to do actions when the timer triggers.
		 */
		function runTimerCallbacks(timer) {
			timerCallbacks.forEach(function(callback) {
				callback(timer);
			});
		}
		
		/**
		 * The minimum number of questions that are required for this game.
		 */
		game.MINIMUM_REQUIRED_QUESTIONS = 7;
		
		/**
		 * Audio that plays when a user selects a correct answer
		 * @param {AudioFile}
		 */
		game.correctAnswerAudio = null;
		
		/**
		 * Audio that plays when a user selects an incorrect answer
		 * @param {AudioFile}
		 */
		game.incorrectAnswerAudio = null;
		
		/**
		 * Audio that plays when a user is on the instructions screen
		 * @param {AudioFile}
		 */
		game.instructionsBackgroundAudio = null;
		
		/**
		 * Audio that plays when a user is actually playing the game.
		 * @param {AudioFile}
		 */
		game.backgroundAudio = null;
		
		/**
		 * Preloads our parent class's audios and all of our own audios, calling the final callback when it's done.
		 * @param {function} The function to call once the audios have been loaded.
		 */
		game.preloadAudios = function(allAudiosLoadedCallback) {
			// get the parent audios loaded, then load our own internal audios
			parentPreloadAudios(function() {
				loadAudios(game, allAudiosLoadedCallback);
			});
		};
		
		/**
		 * Given a choice grade the current question with the time taken to select that choice
		 * @param {string}
		 * @param {TimeSpan}
		 */
		game.gradeSelectedChoice = function(choiceId, timeTakenForAnswer)
		{
			if (this.currentQuestion === null)
			{
				throwError("Cannot grade question as currentQuestion is undefined");
				return;
			}

			var choice = this.getChoiceForId(choiceId);

			if (choice === null)
			{
				throw new Error("Choice was not found in data array for " + choiceId);
			}

			// create a new UserAnswer object
			var userAnswer = new module.UserAnswer();
			userAnswer.answer = choice;

			userAnswer.time = timeTakenForAnswer;
			userAnswer.formattedTime = timeTakenForAnswer.getFormattedTimeInSeconds(1);

			if (this.currentQuestion.correctAnswer.id === choiceId)
			{
				userAnswer.isCorrect = true;
				this.currentQuestion.status = module.STATUS_CORRECT;
				userAnswer.score = getScore(consecutiveNumberOfCorrectAnswers);
				consecutiveNumberOfCorrectAnswers += 1;
			}
			else
			{
				userAnswer.isCorrect = false;
				this.currentQuestion.status = module.STATUS_INCORRECT;
				consecutiveNumberOfCorrectAnswers = 1;
			}
			this.currentQuestion.userAnswer = userAnswer;
		};
		
		/**
		 * Returns the current time remaining in the game.
		 */
		game.getTime = function() {
			var timer = this.getTimer();
			if (timer) {
				return timer.getTimeRemainingSpan();
			}
		};
		
		/**
		 * Retrieves the results for the game.  Including the wordsCorrect, the totalTime, and results.
		 * @param return object with {results: {array of Question}, wordsCorrect: {number}, totalTime: {seconds}, hasLeaderBoard: {boolean},
		 * leaderBoardScore: {points: {Number}, stars: {Number}, time: {Number}} }
		 */
		game.getGameResults = function() {

			if (endGameResult === null)
			{
				// time remaining 
				var timeSpan = this.getTime();
				
				var seconds = GAME_TIME - timeSpan.getTimeInSeconds();
				var score = this.scorer.calculateScore(this.questions, timeSpan);

				endGameResult = {
					'results': this.questions
					,'totalTime': seconds.toFixed(1)
					,'hasLeaderboard': this.hasLeaderboard
					,'leaderBoardScore': score
					,'correct': score.questionsCorrect
				};
			}

			return endGameResult;
		};
		
		/**
		 * Returns true if the game is playing an answer audio right now, false otherwise
		 * @return {boolean}
		 */
		game.isPlayingAnswerAudio = function() {
			return currentAnswerAudioTool !== null;
		};
		
		/**
		 * Plays the answer audio for the current question based on the question status.  If the question is correct it plays
		 * the correct answer audio, incorrect gets the incorrect answer audio.  When the playback is finished it will call the passed in
		 * playFinishedCallback
		 * @param {function}
		 */
		game.playAnswerAudio = function(playFinishedCallback) {
			
			if (this.currentQuestion !== null) {
				var questionStatus = this.currentQuestion.status; 
				if (questionStatus === module.STATUS_CORRECT && audioCorrectTool) {
					currentAnswerAudioTool = audioCorrectTool;
				}
				else if (questionStatus == module.STATUS_INCORRECT && audioIncorrectTool) {
					currentAnswerAudioTool = audioIncorrectTool;
				}
				else {
					currentAnswerAudioTool = null;
				}
			}
			else {
				throwError("current question is null, this should never happen");
				currentAnswerAudioTool = null;
			}
			
			if (currentAnswerAudioTool) {
				var callBack = handlePlayAnswerAudioFinished(playFinishedCallback);
				currentAnswerAudioTool.setAudioFinishedCallback(callBack);
				currentAnswerAudioTool.play();
			}
			else {
				playFinishedCallback();
			}
		};
		
		/**
		 * Returns true if we should play an audio for the current question.
		 * @return boolean
		 */
		game.shouldPlayAudioQuestion = function() {
			// the question is an audio if the current question has an audio property
			return this.currentQuestion && this.currentQuestion.audio;
		};
		
		/**
		 * validates if the ThisOrThat game model has been setup correctly.  Stores any validation errors in the gameErrors array
		 * in our 
		 */
		game.isGameValid = function() {
			parentIsGameValid();
			
			// NOTE, this is a POINTER to the errors array, as such it is modifying the parent's errors
			// so a call to this.getErrors() after this function has called will have the new errors added to it.
			var gameErrors = this.getErrors();
			if (!gameErrors) {
				throwError("failed to retrieve game errors array");
				return false;
			}
			
			if (game.questions.length < game.MINIMUM_REQUIRED_QUESTIONS) {
				gameErrors.push({message: "There must be at least " + game.MINIMUM_REQUIRED_QUESTIONS + " questions for this game"});
			}
			
			if (!game.correctAnswerAudio) {
				gameErrors.push({message: "There is no correct answer audio file to play"});
			}
			
			if (!game.incorrectAnswerAudio) {
				gameErrors.push({message: "There is no incorrect answer audio file to play"});
			}
			
			return gameErrors.length == 0;
		};
		
		/**
		 * Cleans up the game cleaning up all opened media assets.
		 */
		game.cleanup = function() {
			parentCleanUp();
			
			// cleanup the audio tools
			var destroyTool = game.destroyAudioTool.bind(game);
			loadedAudioTools.forEach(destroyTool);
			
			audioCorrectTool = null;
			audioIncorrectTool = null;
			currentAnswerAudioTool = null;
		};
		
		/**
		 * Plays the instruction audio
		 */
		game.playInstructionsAudio = function() {
			if (instructionsBackgroundMusicTool) {				
				instructionsBackgroundMusicTool.play();
			}
		};
		
		/**
		 * Stops the instruction audio.
		 */
		game.stopInstructionsAudio = function() {
			if (instructionsBackgroundMusicTool) {
				instructionsBackgroundMusicTool.stop();
			}
		};
		
		/**
		 * Plays the background audio
		 */
		game.playBackgroundAudio = function() {
			if (backgroundMusicTool) {
				backgroundMusicTool.play();
			}
		};
		
		/**
		 * Stops the background audio.
		 */
		game.stopBackgroundAudio = function() {
			if (backgroundMusicTool) {
				backgroundMusicTool.stop();
			}
		};
		
		/**
		 * Mute's the volume of the background audio
		 */
		game.muteBackgroundAudio = function() {
			if (backgroundMusicTool) {
				backgroundMusicTool.setVolumeLevel(0);
			}
		};
		
		/**
		 * Unmute's the volume of the background audio
		 */
		game.unMuteBackgroundAudio = function() {
			if (backgroundMusicTool) {
				backgroundMusicTool.setVolumeLevel(1);
			}
		};
		
		/**
		 * Starts the countdown timer of the game.  We show our time by seconds, and update the screen every second.
		 */
		game.startTimer = function() {
			var timer = game.getTimer();
			if (!timer) {
				var timerCallbacks = runTimerCallbacks.bind(game);
				timer = new gaerdvark.utils.GaeTimer();
				timer.setStartSeconds(GAME_TIME);
				// we have to have a stop timer callback and interval callback
				// as we don't get the final update if the timer runs out
				timer.setStopSeconds(0, timerCallbacks);
				timer.setTimerInterval(1000, timerCallbacks);
			}
			game.setTimer(timer);
			timer.start();
		};
		
		/**
		 * Registers a callback to be done whenever a second has passed with the timer.
		 */
		game.registerTimerCallback = function(callback) {
			timerCallbacks.push(callback);
		};
		
		return game;
	}
	
	module.ThisOrThatGameModel = ThisOrThatGameModel;
	
	return module;
})(gaerdvark.models.Game || {});