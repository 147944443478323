gaerdvark.models.Game = (function(module, models) {
	
	/**
	 * Represents the data for the actual game screen of a game.
	 */
	function GameScreenModel() {
		
		/**
		 * The unique identifier for the instructions
		 */
		this.id = null;
		
		/**
		 * The URL to the background image
		 */
		this.background = null;
		
		/**
		 * The object representation of the image.
		 * @param {GameImage}
		 */
		this.backgroundImage = null;
		
		/**
		 * @param {AudioFile}
		 */
		this.backgroundAudio = null;
		
		/**
		 * Whether to immediately start playing the audio
		 * when the audio is loaded.
		 */
		this.backgroundAudioAutoPlay = true;
		
		/**
		 * The class name to use for the game screen.
		 * @param {string}
		 */
		this.className = null;
		
		/**
		 * @param {AudioFile|File}
		 */
		this.correctAudio = null;
		
		/**
		 * @param {AudioFile|File}
		 */
		this.incorrectAudio = null;
	}
	
	module.GameScreenModel = GameScreenModel;
	
	return module;
})(gaerdvark.models.Game || {});	