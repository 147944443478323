gaerdvark.models.Game = (function(module) {
	/**
	 * Keeps track of the audio url and whether it has been loaded or not
	 * 
	 * @param {string} audioURL
	 * @param {string} audioMimetype
	 * @returns {gaerdvark.tools.SoundMatchGame.AudioFile}
	 */
	function AudioFile(audioURL, audioMimetype)
	{
		/**
		 * {boolean}
		 */
		this.loaded = false;

		/**
		 * {string}
		 */
		this.url = audioURL;

		/**
		 * {string}
		 */
		this.mimeType = audioMimetype;
		
		/**
		 * Should the audio file keep playing in a loop.
		 */
		this.loop = false;
		
		/**
		 * Checks to make sure the audio object has a valid url and mimeType.  Returns false if it doesn't.
		 * @return {boolean}
		 */
		this.isValidAudioFile = function ()
		{
			var isValid = true;
			if (typeof this.url !== 'string')
			{
				isValid = false;
			}
			else if (typeof this.mimeType !== 'string')
			{
				isValid = false;
			}

			return isValid;
		};
	}
	module.AudioFile = AudioFile;
	
	return module;
})(gaerdvark.models.Game || {});