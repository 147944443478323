gaerdvark.models.Game = (function(module, $) {
	/**
	 * View controller for Would You Rather Game.  Handles animations.
	 * @param {object} the data to use for the view
	 * @param {string} The html of the view.
	 * @param {object} Hash map of function callbacks the view can call.
	 */
	function WouldYouRatherGameView(viewData, html, actions) {

		var view = new module.BasicView(viewData, html, actions);
		
		var executingAnimation = false;
		
		var containerNode = null;
		
		
		function getRandomDisplay(value){
			if(gaerdvark.utils.isArray(value.display)){
				var index = Math.floor(Math.random() * value.display.length);
				return value.display[index];
			}else{
				return value.display;
			}
		}
		/**
		 * Given a node it adds the select answer handler to the node.  This works across mobile and desktop.
		 * @param {DOMNode} node
		 */
		function addSelectAnswerClickHandler(node) {
			$(node).find(".value").on("vclick", function(evt) {
				// don't trigger the mouse click because we are moving content
				// when you move content it can trigger another click on whatever is under the content
				// 300 ms away
				evt.preventDefault(); 
				actions.choice(evt, $(this).data("choice"));
			});
		}
		
		view.loadContainer = function (name, data) {
			var dynamicOn = this.htmlProcessor.dynamicOn.get(name, data);
			if (dynamicOn && gaerdvark.utils.isArray(dynamicOn))
			{
				return dynamicOn[0].value;
			}
			return null;
		};
		
		view.inAnimation = function() {
			return executingAnimation;
		};
		
		view.displayChoices = function(choice1, choice2, animationSpeed) {
			
			function getDisplay(id){
				if(choice1.id === id){
					return getRandomDisplay(choice1);
				}else{
					return getRandomDisplay(choice2);
				}
			}
			
			if (!animationSpeed) {
				animationSpeed = 'slow';
			}

			var choiceData = {
				value1: choice1
				, value2: choice2
				,"getDisplay":getDisplay
			};
				
			if (containerNode !== null)
			{
				executingAnimation = true;

				// we start the new choices hidden to the far right of our container.  We then slide those answers from the right to
				// their correct position.  At the same time we slide the old answers off the screen from their position to the far left

				// start the new answers to the far right and have them slide to their correct position.
				
				
				var clonedContainerNode = view.loadContainer("quickSelectValue", choiceData);
				addSelectAnswerClickHandler(clonedContainerNode);
				$(clonedContainerNode).hide(); // hide this node so we can then fade it in.
				
				$(containerNode).fadeOut(animationSpeed, function() {
					$(clonedContainerNode).fadeIn(animationSpeed, function() {
						$(containerNode).remove(); // remove should remove all event handlers including child event handlers
						containerNode = clonedContainerNode;
						clonedContainerNode = null;
						executingAnimation = false;
					});
				});
			}
			else
			{
				containerNode = view.loadContainer("quickSelectValue", choiceData);
				addSelectAnswerClickHandler(containerNode);
			}
		};
		
		return view;
	}
	
	module.WouldYouRatherGameView = WouldYouRatherGameView;
	
	return module;
})(gaerdvark.models.Game || {}, jQuery);