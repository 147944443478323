gaerdvark.models.Game = (function(module) {
	/**
	 * Simple object that represents an Answer that a user can select
	 */
	function Answer()
	{
		/**
		 * {string} uuid
		 */
		this.id = null;

		/**
		 * {string} of html content
		 */
		this.content = null;
	}
	
	module.Answer = Answer;
	
	return module;
})(gaerdvark.models.Game || {});
