/**
	 * Trophy Object
	 * @returns {gaerdvark.models.Trophy}
	 */
gaerdvark.models.Trophy = function() {
		this.id = "";
		this.title = "";
		this.description = "";
		this.congratsMessage = "";
		this.iconId = "";
		this.iconUrl = "";
};