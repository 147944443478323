gaerdvark.utils.ToolUtils = (function(toolUtils, Tool) {
	
	/**
	 * Take a content box data element and return the string representation of it.
	 * 
	 * @param {Object} data
	 * @returns {document@call;createElement.innerHTML|String}
	 */
	function convertContentBoxDataToString(data)
	{
		var div = document.createElement("div");
		var html = "";
		try
		{
			var contentBox = new Tool("Content Box");
			contentBox.appendToNode(div);
			var node = contentBox.activate(data);
			html = div.innerHTML;
			contentBox.destruct();
		}
		catch (ex)
		{
			throwError("Failed to process html for content box " + ex);
		}
		return html;
	}
	
	toolUtils.convertContentBoxDataToString = convertContentBoxDataToString;
	return toolUtils;
	
})(gaerdvark.utils.ToolUtils || {}, Tool);	