/* global gaerdvark */
gaerdvark.models.Game = (function(module) {
	
	/**
	 * Sample test data object for the ThisOrThat game.
	 */
	function ThisOrThatTestData() {
		var utils = gaerdvark.utils;
		
		var testData = {
			toolUuid: utils.uuid()
			,questions: []
		};
		
		testData.shuffleQuestions = false;
		
		// useful for debugging asset order, as we can trigger specific errors.
		var audioQuestionType = [true, false, true, false, true, false, true];
		for (var i = 0; i < 7; i++) {
			var question = new module.Question();
			question.id = utils.uuid();
			question.name = "Question " + i;
			if (audioQuestionType[i]) {
				question.audio = new gaerdvark.models.File();
				question.audio.fileUrl = "http://dscmslms.appspot.com/?blobWSK=agpzfmRzY21zbG1zcroBCxIRQmxvYnN0b3JlTWV0YWRhdGEiogFBTUlmdjk0bkMyeXFQVGpUZFNLSVpuY0ZsYUItZDQ3bWdXSlVyTC1sVmxMYVVjWDc1bDQzTWswLWZpeUxFMWdiMGtTdVUyUDdBUUljaFd5WHpvc05DT3hFMnZ2VktjMmVKRjVRTUZtRUp3a2RweHBpRjFPZk82ZWFzX3YzQmFOT1dLSUF3ZjlVVURZblA1bUxXVFY4aElYSXRpamhreVdtS3cMogEGZHMuYW1l";
				question.audio.mimeType = "audio/mpeg";
			}
			else {
				question.content = "This is question " + question.name;
			}
			
			for (var a = 0; a < 2; a++) {
				var answer = new module.Answer();
				answer.id = utils.uuid();
				answer.content = "Q" + i + " Answer " + a;
				question.answers.push(answer);
			}
			question.answers[0].isCorrect = true;
			
			testData.questions.push(question);
		}
		
		var pregame = new module.InstructionsScreenModel();
		pregame.id = utils.uuid();
		pregame.background = "http://192.168.1.76:8888/cms/?blobWSK=ahVkZXYtZHluYWN0aXZlc29mdHdhcmVyXQsSEUJsb2JzdG9yZU1ldGFkYXRhIkZlbmNvZGVkX2dzX2tleTpZWEJ3WDJSbFptRjFiSFJmWW5WamEyVjBMM0pDZVdVNVIwdzJTWE16VlZCM2VXaHVSM052YVdjDKIBBmRzLmNtcw";
		pregame.backgroundAudio = new gaerdvark.models.File();
		pregame.backgroundAudio.fileUrl = "/cms/audio/thisorthat/introMusic.mp3";
		pregame.backgroundAudio.mimeType = "audio/mpeg";
		pregame.className = "onTheFly";
		testData.pregame = pregame;
		
		var game = new module.GameScreenModel();
		game.id = utils.uuid();
		game.background = "http://192.168.1.76:8888/cms/?blobWSK=ahVkZXYtZHluYWN0aXZlc29mdHdhcmVyXQsSEUJsb2JzdG9yZU1ldGFkYXRhIkZlbmNvZGVkX2dzX2tleTpZWEJ3WDJSbFptRjFiSFJmWW5WamEyVjBMMnhRZVhWdFlqQkdVMFpIYUVRMVltbFpZV3RNU1ZFDKIBBmRzLmNtcw";
		//game.background = "http://192.168.1.76:8888/cms/?blobWSK=ahVkZXYtZHluYWN0aXZlc29mdHdhcmVyXQsSEUJsb2JzdG9yZU1ldGFkYXRhIkZlbmNvZGVkX2dzX2tleTpZWEJ3WDJSbFptRjFiSFJmWW5WamEyVjBMMkpaT0cxNVVERlVUVzFtZEhkS2QyVlZaR0pEVjNjDKIBBmRzLmNtcw";
		game.className = "onTheFly";
		
		game.backgroundAudio = new gaerdvark.models.File();
		game.backgroundAudio.fileUrl = "/cms/audio/thisorthat/gameMusic.mp3";
		game.backgroundAudio.mimeType = "audio/mpeg";
		
		testData.game = game;
		testData.toolUuid = utils.uuid();
		
		var correctAudio = new gaerdvark.models.File();
		correctAudio.fileUrl = "/cms/audio/thisorthat/correctAnswer.mp3";
		correctAudio.mimeType = "audio/mpeg";
		
		var incorrectAudio = new gaerdvark.models.File();
		incorrectAudio.fileUrl = "/cms/audio/thisorthat/wrongAnswer.mp3";
		incorrectAudio.mimeType = "audio/mpeg";
		testData.correctAudio = correctAudio;
		testData.incorrectAudio = incorrectAudio;

		var leaderboard = new module.ScoringSettings();
		leaderboard.id = utils.uuid();
		leaderboard.leaderBoardTitle = "On the Fly - Program Overview";
		testData.leaderboard = leaderboard;
		
		var trophyData = new gaerdvark.models.TrophyActivityInstance();
		trophyData.trophyInstanceId = utils.uuid();
		for (var t = 1; t <= 12; t++) {
			var trophy = new gaerdvark.models.Trophy();
			trophy.id = t;
			trophy.title = "Trophy Level " + t;
			trophy.description = "Trophy Description " + t;
			trophy.congratsMessage = "Congratulations you have earned " + trophy.title;
			trophy.iconId = "";
			trophy.iconUrl = "?blobWSK=ahVkZXYtZHluYWN0aXZlc29mdHdhcmVyXQsSEUJsb2JzdG9yZU1ldGFkYXRhIkZlbmNvZGVkX2dzX2tleTpZWEJ3WDJSbFptRjFiSFJmWW5WamEyVjBMMmxaTjNwU1JVUmZVWE14TVdSVFNFUkVSR0ZaTlVFDKIBBmRzLmNtcw";
			trophy.criteria = {
				type: "points"
				,value: (t-1) * 5000
			};
			trophyData.trophies.push(trophy);
		}
		testData.trophyData = trophyData;
		
		return testData;
	}

	module.ThisOrThatTestData = ThisOrThatTestData;
	return module;
})(gaerdvark.models.Game || {});
