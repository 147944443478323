gaerdvark.models.Game = (function(module) {
	
	var MAXIMUM_NUMBER_OF_STARS = 5;
	
	/**
	 * Game Scoring Object for the This Or That Game.  
	 * 
	 * Points for the game are earned through both question accuracy and time * question accuracy.
	 * Each consecutive question the user gets right gives the user an additional bonus score.  Their total bonus scores combined multipled by 1000
	 * equals their bonus score.
	 * 
	 * The timing component of their points is their question accuracy (correct / total) * the amount of time in milliseconds the user had remaining.
	 * 
	 * The user can earn up to 5 stars in this game.  
	 * @param {gaerdvark.tools.BaseGame.ScoringSettings}
	 */
	function ThisOrThatScorer(settings) {
		
		/**
		 * The point range for when a user gets an additional star.  Anything below the first range is 1 star, so they can get a maximum of 5 stars.
		 */
		var POINTS_RANGE = [12000, 21000, 30000, 39000];
		
		/**
		 * Calculates a GameScore with it's points and stars and returns it.
		 * @param {Number} accuracy Accuracy is a number from 0 to 100
		 * @param {TimeSpan} userTime Time the user had remaining
		 */
		this.calculateScore = function(questions, userTime) {
			

			// let's add up our points from questions first
			var totalAccuracyScore = 0;
			var numberCorrect = 0;
			gaerdvark.utils.forEachArray(questions, function(question) {
				totalAccuracyScore += question.userAnswer.score;
				numberCorrect = question.userAnswer.isCorrect ? numberCorrect + 1 : numberCorrect;
			});
			
			// time remaining 
			var milliseconds = userTime.getTimeInMilliseconds();
			
			// two digit percentage
			var accuracyMultiplier = Math.round(numberCorrect / questions.length * 100) / 100;
			
			// now we multiply the milliseconds by the accuracy to get our points
			// because a user cannot ever reach 30 seconds, at most maybe 23-24.
			// do we care if we add the second addition even if they get all the questions wrong.
			var totalTimeScore = Math.round(accuracyMultiplier * milliseconds);
			
			var points = totalAccuracyScore + totalTimeScore;
			
			var stars = 1;
			for (var i =0; i < POINTS_RANGE.length; i++) {
				if (POINTS_RANGE[i] > points) {
					break
				}
				stars = i + 2;
			}
			
			var gameScore = new module.GameScore(points, stars, milliseconds);
			gameScore.maxStars = MAXIMUM_NUMBER_OF_STARS;
			gameScore.questionsCorrect =  numberCorrect;
			gameScore.totalQuestions = questions.length;
			
			return gameScore;
		}
	}
	
	module.ThisOrThatScorer = ThisOrThatScorer;
	return module;
})(gaerdvark.models.Game || {});