gaerdvark.models.Game = (function(module, actions) {	
	/**
	 * Simple view that shows the loading screen while we are prefetching the assets.
	 * @param {Game} the game model that this view uses.
	 * @param {string} The html of the view.
	 * @param {object} Hash map of function callbacks the view can call.
	 */
	function LoadingView(gameData, html, actions) {

		/**
		 * The node that contains this view.
		 */
		this.node = null;

		/**
		 * Process html object that was used to produce this view.
		 * {ProcessHtml}
		 */
		this.htmlProcessor = null;

		/**
		 * Return the html that represents this view
		 * @return {string}
		 */
		this.getHtml = function() {
			return html;
		};

		/**
		 * Return the data to be used in ProcessHtml for the data flag replacements
		 * @return {object}
		 */
		this.getData = function() {
			return this;
		};
	}
	module.LoadingView = LoadingView;
	
	return module;
})(gaerdvark.models.Game || {});