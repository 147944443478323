gaerdvark.models.TestEditors = (function(module, utils) {
	/**
	 * Used to validate a MultipleChoiceQuestion and all of its fields are correctly filled out
	 */
	function MultipleChoiceQuestionValidator() {
		/**
		 * Checks to make sure that the passed in model has all of the required fields filled out
		 * @param {MultipleChoiceQuestion}
		 * @return {array} of Strings containing the specific error messages.  Empty array if there are no errors.
		 */
		this.validate = function(model) {
			var errors = (new module.QuestionValidator()).validate(model);
	
			// now validate that each answer has text in it, and make sure an answer is selected
			var selectedAnswers = model.getSelectedAnswers();
			if (selectedAnswers.length === 0) {
				errors.push("There must be at least one selected answer.");
			}
	
			// now make sure that answers are filled out
			var emptyAnswer = utils.someArray(model.answers, function(answer) {
				return !utils.isValidString(answer.answer);
			});
			if (emptyAnswer) {
				errors.push("No answer can be empty.  Please fill in all empty answers.");
			}
			return errors;
		};
	}

	module.MultipleChoiceQuestionValidator = MultipleChoiceQuestionValidator;

	return module;
})(gaerdvark.models.TestEditors || {}, gaerdvark.utils);