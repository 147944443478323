gaerdvark.models.Game = (function(module) {
	/**
	 * Simple data object that tracks the choice the user selected, the time they took
	 * and whether the choice was correct or not
	 */
	function UserAnswer()
	{
		/**
		 * {TimeSpan}
		 */
		this.time = null;

		/**
		 * {Answer}
		 */
		this.answer = null;

		/**
		 * {boolean}
		 */
		this.isCorrect = null;
		
		/**
		 * Tracks the score for this object.  If questions are worth one, then the score is 1
		 * If a question has some multiplier associated to it, then the score can be this multiplier
		 * {Number}
		 */
		this.score = 0;
	}
	module.UserAnswer = UserAnswer;
	
	return module;
})(gaerdvark.models.Game || {});