if(!gaerdvark.models.ckeditor){
	gaerdvark.models.ckeditor = {};
}
/**
 * 
 * 
 */ 
gaerdvark.models.ckeditor.CkEditorUtils = function (){
	/**
	 * Gets finds an ancestor element. strElement is the name of the ancenstor tag you want to find.
	 * returns null if selected element isn't the type that you have sent in.
	 *
	 * An example is you can say get me that span tag that is selected.
	 *
	 * if you have selected [as<span>blah] blah</span> or as<span>[blah] blah</span> etc
	 *
	 * Then it will return the span element. You can then check the element for attributes or whatever.
	 *
	 * If you get null then it didn't find an ancestor tag that you have asked for.
	 *
	 */
	function getSelectedElement(editor, strElement){
		try
		{
			var selection = editor.getSelection();
			if ( selection.getType() == CKEDITOR.SELECTION_ELEMENT )
			{
				var selectedElement = selection.getSelectedElement();
				if ( selectedElement.is( strElement ) )
					return selectedElement;
			}

			var range = selection.getRanges( true )[ 0 ];
			range.shrink( CKEDITOR.SHRINK_TEXT );
			var root = range.getCommonAncestor();
			return root.getAscendant( strElement, true );
		}
		catch( e ) { return null; }
	}
	
	return {
		"getSelectedElement":getSelectedElement
	};
}();