gaerdvark.models.TestV2 = (function (module) {

	function TestSaveScoreData() {
		// position must be in integer, not a string
		this.position = 1; //temporary hard coded until we can identify how to do this
		this.userId = "";
		this.pageId = "";
		this.testId = "";
		this.correct = 0;
		this.outOf = 0;
		this.instructorGradedData = {};
		this.name = "";
		this.resultData = {};
		this.testType = "";
		this.testSubType = "";
		this.gradeId = "";
	}
	module.TestSaveScoreData = TestSaveScoreData;
    return module;
})(gaerdvark.models.TestV2 || {});