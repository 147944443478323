gaerdvark.models.TestV2 = (function (module) {

    function TestModelData() {
        this.id = gaerdvark.utils.uuid();
        this.name = "";
        this.settings = new module.TestModelSettingsData();
        this.groups = [];
        this.correct = 0;
        this.outOf = 0;
    }
    
    module.TestModelData = TestModelData;
    return module;
})(gaerdvark.models.TestV2 || {});