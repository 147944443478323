gaerdvark.models.CodingCrowdSourcingContest = function(){
    var me = this,
    utils = gaerdvark.utils,
    MAX_SUBMISSIONS = 3,
    completionData = null;
    
    this.startDate = new Date();
    this.courseId = 0;
    this.id = null; 
    this.title = "";
    this.trophyData = null;
    this.scenarioList = [];
    this.winner = null; //user data about the winner
    this.locationIndex = 0;
    
    var ScenarioData = function (){
        this.scenarioId = "";
        this.isComplete = false;
        this.userList = [];
    }

    function isWinnerChosen(){
        if(utils.isEmpty(this.winner)){
            return false;
        }
        return true;
    }
    
    function getScenarioDataById(scenarioId){
        return utils.findFirstElementByProperty(me.scenarioList, "scenarioId", scenarioId);
    }

    function createScenarios(scenarioIds){
        for(var i = 0; i < scenarioIds.length; i++){
            var scenarioData = new ScenarioData();
            scenarioData.scenarioId = scenarioIds[i];
            this.scenarioList.push(scenarioData);
        }
    }

    function addUser(scenarioId, userId){
        var scenarioData = getScenarioDataById(scenarioId);
        scenarioData.userList.push(userId);
        if(scenarioData.userList.length >= MAX_SUBMISSIONS){
            scenarioData.isComplete = true;
        }
    }

    function getStartDate(){
        return gaerdvark.utils.formatDateDMY(new Date(me.startDate));
    }

    function getCompletionData(){
        var count = 0
        , scenarioCount = me.scenarioList.length
        , submissions = 0,
        contributors = 0,
        contributorsCount = {};

        if(completionData !== null){
            return completionData;
        }

        for(var i = 0; i < scenarioCount; i++){
            if(me.scenarioList[i].isComplete){
                count++;
            }

            submissions += me.scenarioList[i].userList.length;

            //this is for the contrubutor count. 
            if(me.scenarioList[i].userList.length > 0){
                for(var a = 0; a < me.scenarioList[i].userList.length; a++){
                    contributorsCount[ me.scenarioList[i].userList[a] ] = null;
                }
            }
        }

        contributors = Object.keys(contributorsCount).length;

        if(count === 0){
            var percent = 0;
        }else{
            percent = Math.ceil((count / scenarioCount) * 100);
        }
        
        completionData =  {
            "total":scenarioCount
            ,"completed":count
            ,"percent":percent
            ,"submissions":submissions
            ,"contributors":contributors
        }

        return completionData;
    }

    function outputCompletionValue(type){
        var completionData = getCompletionData();
        if(utils.isEmpty(type)){
            return "";
        }

        return completionData[type];
    }

    function isComplete(){
        var completionData = getCompletionData();
        if(completionData.percent >= 100){
            return true;
        }
        return false;   
    }

    function getNextScenario(){
        var list = this.scenarioList;
        var newList = list.filter(function(item){
            //if they have done this scenario then skip it.
            if( item.userList.indexOf(_SESSION.userID) > -1 ){
                return false;
            }
            //if it is complete then skip it.
            return !item.isComplete;
        });

        newList.sort(function (a, b){
            return utils.sorts.sortDescending(a.userList.length, b.userList.length);
        });

        if(newList.length > 0){
            return newList[0].scenarioId;
        }

        return null;
    }

    function isInProcessOfChoosingWinner(){
        if(me.locationIndex > 0){
            return true;
        }
        return false;
    }

    function canDelete(){
        var completionData = getCompletionData();

        if(completionData.percent === 0 || completionData.percent === 100){
            return true;
        }

        return false;
    }

    this.getCompletionData = getCompletionData;
    this.addUser = addUser;
    this.createScenarios = createScenarios;
    this.getStartDate = getStartDate;
    this.outputCompletionValue = outputCompletionValue;
    this.isComplete = isComplete;
    this.getNextScenario = getNextScenario;
    this.isWinnerChosen = isWinnerChosen;
    this.isInProcessOfChoosingWinner = isInProcessOfChoosingWinner;
    this.canDelete = canDelete;
};