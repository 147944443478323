gaerdvark.models.TestV2 = (function(module) {
    
    function TestModelSettingsData() {
        this.submitsAllowed = 0;
        this.displayMethod = "test";
        this.testTimer = '';
        this.showExitButton = true;
        this.showPage = true;
        this.showExitButton = true;
        this.showLaunchButton = false;
        this.autoShowPage = false;
        this.testMultiplier = 1;
        this.testType = null;
        this.testSubType = null;
        this.gradeId = null;
        this.time = null;
        this.showClearTestButton = true;
        this.showRetakeTestButton = true;
        this.showSubmitButton = false;
        this.submitBeforeContinue = false;
        this.saveResultsForReview = false;
        this.hideAllFeedback = false;
    }

	module.TestModelSettingsData = TestModelSettingsData;
    return module;
})(gaerdvark.models.TestV2 || {});