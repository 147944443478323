gaerdvark.models.Game = (function(module) {	
	/**
	 * View that shows the results on the page, lets users replay the audios and restart the game
	 * @param {object} data to initialize the view
	 * @param {Game} the game model that this view uses.
	 * @param {string} The html of the view.
	 * @param {object} Hash map of function callbacks the view can call.
	 * @param {ProgressIcons} the object that has all of the progress icon data.
	 */
	function ResultsView(initData, game, html, actions, progressIcons)
	{
		/**
		 * The node that contains this view.
		 */
		this.node = null;

		/**
		 * Process html object that was used to produce this view.
		 * {ProcessHtml}
		 */
		this.htmlProcessor = null;

		/**
		 * Class to put on the time
		 */
		this.timeClass = '';

		if (initData)
		{
			this.timeClass = initData.timeClass ? initData.timeClass : '';
		}

		/**
		 * Return the data to be used in ProcessHtml for the data flag replacements
		 * @return {object}
		 */
		this.getData = function() {

			var gameResults = game.getGameResults();
			gaerdvark.utils.forEachArray(gameResults.results, function(answerResult) {
				answerResult.hasAudio = answerResult.audio !== null;
				answerResult.icon = progressIcons.getIconForStatus(answerResult.status);
			});
			gameResults.avatar = _SESSION.avatarUrl;
			return gameResults;
		};

		/**
		 * Return the html that represents this view
		 * @return {string}
		 */
		this.getHtml = function() {
			return html;
		};

		/**
		 * Return the images to load, which for this View is none.
		 * @return {array}
		 */
		this.getImagesToLoad = function() {
			return [];
		};
	}
	module.ResultsView = ResultsView;
	
	return module;
})(gaerdvark.models.Game || {});