/**
	 * Trophy Object
	 * @returns {gaerdvark.models.EarnedTrophy}
	 */
gaerdvark.models.EarnedTrophy = function() {
	
	/**
	 * The particular trophy id of the level the user earned 
	 */
	this.trophyEarnedId = null;
	
	/**
	 * The unique instance of the trophy activity
	 */
	this.trophyInstanceId = null;
	
	/**
	 * Array of {gaerdvark.models.Trophy}
	 */
	this.levels = [];
};