gaerdvark.models.Game = (function(module) {
	
	/**
	 * Progress Icons model for the This Or That Game.  Extends {gaerdvark.models.Game.ProgressIcons}
	 * @see {gaerdvark.models.Game.ProgressIcons}
	 */
	function ThisOrThatProgressIcons() {
		
		var progressIcons = new gaerdvark.models.Game.ProgressIcons();
		
		progressIcons.setIcon(module.STATUS_CORRECT, '/lms/ThisOrThat/right.png');
		progressIcons.setIcon(module.STATUS_INCORRECT, '/lms/ThisOrThat/wrong.png');
		progressIcons.setIcon(module.STATUS_CURRENT, '/lms/ThisOrThat/playbutton-blank.png');
		progressIcons.setIcon(module.STATUS_INCOMPLETE, '/lms/ThisOrThat/playbutton-blank.png');
		
		return progressIcons;
	}
	
	module.ThisOrThatProgressIcons = ThisOrThatProgressIcons;
	
	return module;
})(gaerdvark.models.Game || {});