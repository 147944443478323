gaerdvark.models.Game = (function(me) {
	/**
	 * Data object sent up to the server for saving the leaderboard
	 */
	function LeaderBoardDataSave() {
		this.instanceUuid = null;
		this.courseId = 0;
		this.relatedId = "";
		this.title = "";
		this.gameType = "";
		this.points = 0;
		this.milliseconds = 0;
		this.stars = 0;
		this.maxStars = 5;
		this.userDisplayName = _SESSION.displayName;
		this.userAvatarUrl = _SESSION.avatarUrl;
}
	
	me.LeaderBoardDataSave = LeaderBoardDataSave;
	return me;
})(gaerdvark.models.Game || {});