gaerdvark.models.Game = (function(module) {
	
	var models = gaerdvark.models;
	
	/**
	 * Simple view that shows the instructions on the page and lets the user start the game.
	 * @param {object} data to initialize the view
	 * @param {Game} the game model that this view uses.
	 * @param {string} The html of the view.
	 * @param {object} Hash map of function callbacks the view can call.
	 */
	function InstructionsView(initData, game, html, actions)
	{
		/**
		 * The node that contains this view.
		 */
		this.node = null;

		/**
		 * Process html object that was used to produce this view.
		 * {ProcessHtml}
		 */
		this.htmlProcessor = null;

		/**
		 * Container for the instructions on the page
		 * @param {Container}
		 */
		this.instructions = new models.Container('instructionsContainer');

		/**
		 * Container for the start button the user can click to start the game
		 * @param {Container}
		 */
		this.start = new models.Container('startContainer');

		/**
		 * Background image  for the container
		 * @param {GameImage}
		 */
		this.background = '';

		/**
		 * Class name to be able to target the instructions on the game
		 */
		this.className = '';

		if (initData)
		{
			this.instructions = new models.Container('instructionsContainer',initData.instructions);
			this.start = new models.Container('startContainer', initData.start);
			this.background = new models.Game.GameImage(initData.background, 'background');
		}

		/**
		 * Return the html that represents this view
		 * @return {string}
		 */
		this.getHtml = function() {
			return html;
		}

		/**
		 * Return the data to be used in ProcessHtml for the data flag replacements
		 * @return {object}
		 */
		this.getData = function() {
			return this;
		}

		/**
		 * Return the images to preload for the game
		 * @return {array} of GameImage objects to preload
		 */
		this.getImagesToLoad = function() {
			return [this.background];
		};
	}
	
	module.InstructionsView = InstructionsView;
	
	return module;
})(gaerdvark.models.Game || {});	