gaerdvark.models.Game = (function(module, $) {
	
	/**
	 * Helper object that will display views and handle the transitions between other views.
	 */
	function ViewLoader(group, actions, gameId) {
		
		if (!group) {
			throwError("Group was not defined in ViewLoader");
		}
		
		/**
		 * Pointer to the current view being displayed.
		 */
		var currentView = null;
		
		var loaderIsDestroyed = false;
		
		/**
		 * Takes a javascript 'view' object and displays it on the page.
		 * 
		 * @param {Object} viewObject
		 * @param {function} viewDisplayedCallback
		 * @returns {undefined}
		 */
		this.displayView = function (viewObject, viewDisplayedCallback)
		{

			var oldView = currentView;
			currentView = viewObject;

			var loadView = function() {
				if (loaderIsDestroyed) {
					return;
				}
				
				var procHtmlObj = new ProcessHtml(group);

				procHtmlObj.processHtml(currentView.getHtml(), currentView.getData(), actions);

				// Process HTML will not replace anything in a TOOL LOAD if the rootNodesUpdated flag is not set to true
				// since the toolLoad callback's get executed in a timeout after javascript has finished, setting this flag to true
				// will make sure the tools are loaded properly.
				procHtmlObj.setRootNodesUpdated(true);

				currentView.node = procHtmlObj.getCurrentHtmlTree();
				currentView.htmlProcessor = procHtmlObj;

				var sectionView = document.getElementById('sectionView' + gameId);
				if (sectionView) {
					sectionView.appendChild(currentView.node);
				} else {
					throwError("Could not find dom node sectionView" + gameId);
				}

				// now do any post operations
				if (currentView.postLoad)
				{
					currentView.postLoad();
				}

				if (typeof viewDisplayedCallback === 'function')
				{
					viewDisplayedCallback();	
				}
			};

			if (oldView != null && oldView.node != null)
			{
				var hideOptions = {
					"complete":
						function() {
							// if we've been destroyed just return and do nothing
							if (loaderIsDestroyed) {
								return;
							}
							
							$(oldView.node).remove();
							oldView.node = null;
							loadView();
						}
					,"effect":"slide"
				};

				$(oldView.node).hide(hideOptions);
			}
			else
			{
				loadView();
			}
		};
		
		this.getCurrentView = function() {
			return currentView;
		};
		
		this.destruct = function() {
			loaderIsDestroyed = true;
		};      
	}
	
	module.ViewLoader = ViewLoader;
	
	return module;
})(gaerdvark.models.Game || {}, jQuery);