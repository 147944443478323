/* global gaerdvark */
gaerdvark.models.Game = (function(module) {
	/**
	 * Represents a question in the system
	 */
	function Question()
	{
		/**
		 * {string}
		 */
		this.id = null;

		/**
		 * {AudioFile}
		 */
		this.audio = null;
		
		/**
		 * The text string to display for the question.
		 */
		this.content = null;

		/**
		 * {string}
		 */
		this.name = null;

		/**
		 * {Answer}
		 */
		this.correctAnswer = null;

		/**
		 * {UserAnswer}
		 */
		this.userAnswer = null;

		/**
		 * {string} image url
		 */
		this.icon = null;

		/**
		 * {string} current status of the question
		 */
		this.status = module.STATUS_INCOMPLETE;

		/**
		 * {array} of Answer objects.
		 */
		this.answers = [];
		
		/**
		 * Checks to see if the data properties for this question are set correctly in order for it to be used in a game.
		 * If there are any errors it returns them in an array
		 * @return {Array} of error messages.
		 */
		this.validate = function() {
			var errors = [];
			if (!this.id) {
				errors.push({message: "Question is missing id property"});
				return errors;
			}
			
			if (!this.correctAnswer) {
				errors.push({message: "Question " + this.id + " is missing the correct answer"});
			}
			
			
			if (!(this.audio || this.content)) {
				errors.push({message: "Question " + this.id + " must have it's content set or a valid audio file"});
			}
			else if (this.audio && !this.audio.isValidAudioFile()) {
				errors.push({message: "Question " + this.id + " does not have a valid audio file to play"});
			}
			
			return errors;
		};
	}
	module.Question = Question;
	
	return module;
})(gaerdvark.models.Game || {});	