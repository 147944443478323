gaerdvark.models.Game = (function (me) {
	/**
	 * Object representing a score of the game as calculated by calculateScore
	 * @param {Number} points earned
	 * @param {Number} stars number of stars that the player earned.
	 * @param {Number} time in millseconds that the player played.
	 */
	function GameScore(points, stars, time)
	{
		this.points = points;
		this.stars = stars;
		this.time = time;
		
		/**
		 * Used in some games to keep track of the number of questions a user
		 * actually got correct
		 */
		this.questionsCorrect = null;
		
		/**
		 * Used in some games to keep track of the total number of questions a
		 * user answered.
		 */
		this.totalQuestions = null;
		
		/**
		 * The maximum number of stars that could be earned for this game.
		 */
 		this.maxStars = null;
	}
	
	me.GameScore = GameScore;
	
	return me;
})(gaerdvark.models.Game || {});