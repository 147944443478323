gaerdvark.models.Game = (function(module) {
	
	/**
	 * Factory method that given an audio url and a callback function will create the correct
	 * audio player tool and when it is ready for the audio to play will call the passed in callback
	 * @param {Tool} rootTool typically the 'me' pointer in any tool that is used to create other tools.
	 * @param {DOMElement} The dom element to place all the audio files into. 
	 * @param {AudioFile} audioFile file that contains the url to play / preload
	 * @param {function} loadedCallback the callback to call when the audio is ready to play.
	 */
	function createAudioTool(rootTool, placementNode, audioFile, loadedCallback)
	{
		var soundPlayer = rootTool.createTool("MultiMedia");

		var soundSettings = {
				"media": [{
					"fileUrl": audioFile.url
					,'mimeType': audioFile.mimeType
				}]
				,"readyCallback": loadedCallback
				,"displayController":false
		};
		
		if (audioFile.loop) {
			soundSettings.loop = audioFile.loop;
		}

		//soundPlayer.appendToNode(placementNode);
		soundPlayer.activate(soundSettings);
		return soundPlayer;
	}
	
	/**
	 * Returns a function that can be used to create audio tools from AudioFile objects.
	 * @param {Tool} rootTool typically the 'me' pointer in any tool that is used to create other tools.
	 * @param {DOMElement} The dom element to place all the audio files into. 
	 * @return {function} function(AudioTool, function) -> {Tool} takes an audio tool and function callback and returns the newly created Tool 
	 */
	function AudioFileFactory(rootTool, audioPlacementNode) {
		return createAudioTool.bind(null, rootTool, audioPlacementNode);
	}
	
	module.AudioFileFactory = AudioFileFactory;
	
	return module;
})(gaerdvark.models.Game || {});