gaerdvark.models.ClientCMSSettings = function (){
	
	function ThisOrThatGameSettings(){
		return {
			"pregameBackgroundImage":null
			,"gameBackgroundImage":null
			,"pregameBackgroundAudio":null
			,"gameBackgroundAudio":null
			,"correctAnswerAudio":null
			,"incorrectAnswerAudio":null
		};
	}
	
	this.ThisOrThatGameSettings = ThisOrThatGameSettings();
};