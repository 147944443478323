gaerdvark.models = (function(module, send, createSendObject) {	
	
	var DEFAULT_WRITING_CRITERIA = "Correctly answered the essay question";
	var DEFAULT_SPEAKING_CRITERIA = "Correctly answered the speaking question";
	var DEFAULT_NUM_OUT_OF = 10;
	
	var gradingRubrics = {};
	gradingRubrics["default"] = getDefaultGradingSection();
	
	/**
	 * The default grading section is used when someone has not specified a grading rubric.
	 */
	function getDefaultGradingSection() {
		return {
			"speaking": {
				"scoreStep": 1
				,"maxScore": DEFAULT_NUM_OUT_OF
				,"label": "Default Speaking"
				,"categories": ["default"]
				,"default": {
					"name": "Speaking Ability"
					,"criteria": [{"id": 1, "text": DEFAULT_SPEAKING_CRITERIA }]
				}
				,"comments": []
				,"advice": []
			}
			,"writing": {
				"scoreStep": 1
				,"maxScore": DEFAULT_NUM_OUT_OF
				,"label": "Default Writing"
				,"categories": ["default"]
				,"default": {
					"name": "Writing Ability"
					,"criteria": [{"id": 1, "text": DEFAULT_WRITING_CRITERIA }]
				}
				,"comments": []
				,"advice": []
			}
		};
	}
	
	function GradingRubricLoader() {
		var rubricLoader = this;
		
		this.isRubricLoaded = function(gradingRubric, gradingRubricTask) {
			var rubric = this.getRubric(gradingRubric, gradingRubricTask);
			
			return rubric !== null;
		};
		
		this.getRubric = function(gradingRubric, gradingRubricTask) {
			/**
			 * We have to give a copy of the data... as users can actually modify this.
			 */
			if (gradingRubrics.hasOwnProperty(gradingRubric)) {
				var clonedCopy = gaerdvark.utils.cloneObject(gradingRubrics[gradingRubric]);
				return clonedCopy;
			}
			return null;
		};
		
		this.isDefaultRubric = function(rubric) {
			if (typeof rubric === "string") {
				return rubric === "default";
			}
			else if (typeof rubric === "object") {
				return rubric === gradingRubrics["default"];
			}
			return false;
		};
		
		this.loadRubric = function(gradingRubric, gradingRubricTask, callback) {
			var data = {
					gradingRubric: gradingRubric
					// TODO: stephen it's looking like the grading rubric task is unused...
					,gradingRubricTask: gradingRubricTask
			};
			var action = "Get Grading Rubric";
			send(createSendObject(action, data, function(sendObj) {
				var rubric = null;
				if (sendObj.OK && sendObj.response && sendObj.response.data) {
					rubric = sendObj.response.data;
					gradingRubrics[gradingRubric] = rubric;
				}
				else {
					throwError("Failed to load grading rubric for grading rubric: " + gradingRubricTask + " and grading rubric task: " + gradingRubric);
				}
				// this will make sure a copy is made.
				callback(rubricLoader.getRubric(gradingRubric, gradingRubricTask));
			}));
		};
	}
	
	module.GradingRubricLoader = GradingRubricLoader;
	
	return module;
})(gaerdvark.models || {}, send, createSendObject);