gaerdvark.models.Game = (function(module) {
	
	/**
	 * Simple view for handling game errors.
	 * @param {Game} the game model that this view uses.
	 * @param {string} The html of the view.
	 * @param {object} Hash map of function callbacks the view can call.
	 */
	function ErrorView(game, html, actions)
	{
		var gameErrors = [];

		/**
		 * The node that contains this view.
		 */
		this.node = null;

		/**
		 * Process html object that was used to produce this view.
		 * {ProcessHtml}
		 */
		this.htmlProcessor = null;

		/**
		 * Return the data to be used in ProcessHtml for the data flag replacements
		 * @return {object}
		 */
		this.getData = function() {

			gameErrors = game.getErrors();

			return {
				errors: gameErrors
			};
		};

		/**
		 * Return the html that represents this view
		 * @return {string}
		 */
		this.getHtml = function() {
			return html;
		};

		this.postLoad = function() {
			// throw the detailed errors
			gaerdvark.utils.forEachArray(gameErrors, function(error) {
				throwError("An error occurred in the game.  Detailed message: " + error.detailedError);
			});
		};
	}
	module.ErrorView = ErrorView;
	
	return module;
})(gaerdvark.models.Game || {});		