gaerdvark.models.TestEditors = (function(module, utils) {
	/**
	* Represents an Answer for a {Question} object.
	*/
	function Answer(data) {
		var _answer = this;

		/**
		 * The unique identifier for the answer
		 * @type {String}
		 */
		this.id = (data && utils.isValidString(data.id)) ? data.id : utils.uuid();


		/**
		 * index represents the numeric index in the answers array
		 * @type {Number}
		 */
		this.index = 0;

		/**
		 * Represents the alpha representation of the index in the answers array.
		 * @type {String}
		 */
		this.sequence = (data && utils.isValidString(data.sequence)) ? data.sequence : "";

		/**
		 * The html text of the answer
		 * @type {String}
		 */
		this.answer = (data && utils.isValidString(data.answer)) ? data.answer : "";

		/**
		 * The feedback to show for this answer when it's graded
		 * @type {String}
		 */
		this.feedback = (data && utils.isValidString(data.feedback)) ? data.feedback : "";

		/**
		 * Whether this answer is selected as a correct answer for the question
		 * @type {String}
		 */
		this.correct = (data && typeof data.correct === 'boolean') ? data.correct : false;

		/**
		 * What is the numeric index value for the answer.  Same as the index, but this is used in the LMS
		 * and is kept for legacy purposes..
		 */
		this.value = (data && utils.isValidString(data.value)) ? data.value : "";
	}

	module.Answer = Answer;
	
	return module;
})(gaerdvark.models.TestEditors || {}, gaerdvark.utils);