gaerdvark.models.TestEditors = (function(module, utils) {

	/**
	 * Used to convert 1 to A, 2 to B, 27 to AA ...
	 * Does not currently support AAA
	 */
	function getSequenceLetter(index) {
		var first = Math.floor(index / 26);
		var sequence = "";
		if(index > 25) {
			sequence += String.fromCharCode(first + 64);
		}
		index -= first * 26;
		sequence += String.fromCharCode(index + 65);
		return sequence;
	}
	
	/**
	 * Represents a MultipleChoiceQuestion model.  Inherits from Question.
	 */
	function MultipleChoiceQuestion(data) {
		var _model = this;
		
		if (!data) {
			data = {};
		}
		data.type = (data && utils.isValidString(data.type)) ? data.type : "Multiple Choice";

		var question = new module.Question(data);
		
		var parentGetData = question.getData.bind(question);
		
		/**
		 * Determines whether the question is a radio single answer only option or a multi answer checkbox
		 */
		question.checkboxRadio = (data && utils.isValidString(data.checkboxRadio)) ? data.checkboxRadio : "radio";

		/**
		 * Whether the questions are to be displayed vertically or horizontally in the LMS
		 */
		question.displayDirection = (data && utils.isValidString(data.displayDirection)) ? data.displayDirection : "vertical";

		/**
		 * What is the answer set index... always 0 as we don't edit in groups now.
		 */
		question.answerSetIdx = 0;

		/**
		 * Answers represent {Answer} objects and are an array inside of one array which is for backwards compatibility with the old
		 * lms data format.
		 */
		question.answers = [];

		/**
		 * Go through each answer and update the index, and sequence value based upon the order of the questions.
		**/
		question.updateAnswerSequences = function() {
			var sequence = 0;
			utils.forEachArray(question.answers, function(answer) {
				answer.index = sequence;
				answer.value = answer.index + ''; // not sure why the value is supposed to be the index... and why it has to be a string...
				answer.sequence = getSequenceLetter(sequence);
				sequence++;
			});
		};

		/**
		* Select the answer in the question based upon the answer id.
		* @param {String} the id of the answer.
		*/
		question.selectAnswer = function(id) {
			utils.forEachArray(question.answers, function(answer) {
				if (answer.id === id) {
					answer.correct = true;
				}
				// if we only allow single select then mark everything else as false
				else if (question.checkboxRadio === 'radio'){
					answer.correct = false;
				}
			});
		};

		/**
		* Return true if the answer given by the answer id is selected in the model or not.
		* @param {string} answer id.
		* @return {boolean}
		*/
		question.isAnswerSelected = function(id) {
			var answer = question.getAnswerById(id);
			if (answer) {
				return answer.correct;
			}
			return false;
		};

		/**
		* Unselect the answer provided by the answer id.
		* @param {string} the answer id
		*/
		question.unselectAnswer = function(id) {
			var answer = question.getAnswerById(id);
			if (answer) {
				answer.correct = false;
			}
		};

		/**
		* Return an array of the selected answers.
		* @return {array} array of {Answer} objects.
		*/
		question.getSelectedAnswers = function() {
			return utils.filterArray(question.answers, function(answer) {
				return answer.correct === true;
			});
		}

		/**
		* Set the answer at the given index to be the provided answer object.
		* @param {Number}
		* @param {Answer}
		*/
		question.setAnswer = function(index, answer) {
			answer.checkboxRadio = question.checkboxRadio; // make it congruent
			question.answers[index] = answer;
		};

		/**
		* Get the answer at the given index
		* @param {Number}
		* @returns {Answer}
		*/
		question.getAnswer = function(index) {
			return question.answers[index];
		};

		/**
		* Get the answer provided by the answer id.
		* @param {Number}
		* @returns {Answer}
		*/
		question.getAnswerById = function(id) {
			return utils.findFirstElementByProperty(question.answers, 'id', id);
		};

		/**
		 * Adds an answer to this question
		 * @param {Answer}
		 */
		question.addAnswer = function(answer) {
			answer.checkboxRadio = question.checkboxRadio; // make it congruent
			question.answers.push(answer);
		};

		/**
		 * Removes an answer from the question
		 * @param {Answer}
		 */
		question.removeAnswer = function(answer) {
			var index = question.answers.indexOf(answer);
			if (index >= 0) {
				question.answers.splice(index, 1);
			}
		};

		/**
		 * Enables multiple correct answers to be selected.
		 */
		question.enableMultipleCorrectAnswers = function() {
			question.checkboxRadio = "checkbox";
			utils.forEachArray(question.answers, function(answer) {
				answer.checkboxRadio = "checkbox";
			});
		};

		/**
		 * Turns off the ability to select multiple answers for the question.
		 * As there is no way to know what answer is supposed to have marked as selected, all answers are
		 * unselected after making this call.  The client caller must issue a selectAnswer on the answer they want
		 * selected if they need an answer to be selected after this call.
		 */
		question.disableMultipleCorrectAnswers = function() {
			question.checkboxRadio = 'radio';
			utils.forEachArray(question.answers, function(answer) {
				answer.checkboxRadio = "radio";
				answer.correct = false; // reset the checked flag for everything
			});
		};
		
		/**
		 * Retrieve the data for the MultipleChoice question.
		 */
		question.getData = function() {
			var data = parentGetData();
			data.checkboxRadio = question.checkboxRadio;
			data.displayDirection = question.displayDirection;
			return data;
		};


		// setup the answers
		if (data && utils.isArray(data.answers) && data.answers.length > 0) {
			question.answers = utils.mapArray(data.answers[0], function(answer) {
				var answer = new module.Answer(answer);
				answer.checkboxRadio = question.checkboxRadio;
				return answer;
			});
		}
		
		return question;
	}
	
	module.MultipleChoiceQuestion = MultipleChoiceQuestion;

	return module;
})(gaerdvark.models.TestEditors || {}, gaerdvark.utils);