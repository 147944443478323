/* global gaerdvark */

/**
 * File object that represents the internal data for the file.  Also the object
 * that is sent up in the save to the server as well as in the notify events.
 */
gaerdvark.models.File = function()
{
	/**
	 * Unique id for the file
	 * {string}
	 */
	this.id = gaerdvark.utils.uuid();

	/**
	 * The url to the thumbnail for the file
	 * {string}
	 */
	this.thumb = '';

	/**
	 * the url to the file
	 * {string}
	 */
	this.fileUrl = '';

	/**
	 * The display name of the file
	 * {string}
	 */
	this.name = '';

	/**
	 * The original file name
	 * {string}
	 */
	this.fileName = '';

	/**
	 * File description
	 * {string}
	 */
	this.description = '';

	/**
	 * The display name of the person that uploaded the file
	 * {string}
	 */
	this.uploader = '';

	/**
	 * The id of the person that uploaded the file
	 * {string}
	 */
	this.uploaderId = '';

	/**
	 * Whether the file is hidden (not being used anymore) or not
	 * {boolean}
	 */
	this.hidden = false;

	/**
	 * Array of strings that represent the keywords for the file
	 */
	this.keywords = [];

	/**
	 * The type of the file.
	 * @see {BlobstoreMetadata.BlobType}
	 */
	this.type = 'image';

	/**
	 * Is this a local image being served up from gaerdvark instead of appengine's Blobstore
	 */
	this.isNotBlobstoreImage = false;

	/**
	 * What category does this file belong to.
	 */
	this.category = null;

	/**
	 * What is the maximum size in dimensions that this file can be
	 */
	this.maxSize = null;

	/**
	 * Width of an image if the file is an image
	 */
	this.width = null;

	/**
	 * Height of an image if the file is an image.
	 */
	this.height = null;

	/**
	 * Size of the file in bytes
	 */
	this.fileSize = null;

	/**
	 * File size in a string format
	 */
	this.fileSizeDisplay = "";

	/**
	 * Boolean flag that indicates if the file has dimensions or not.
	 */
	this.hasDimensions = false;
	/**
	 * mimeType is the mimetype of the file.
	*/
	this.mimeType = '';
};