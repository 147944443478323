gaerdvark.models.TestEditors = (function(module, utils) {
	
	/**
	 * Used to grab the html representation of a MultipleChoiceQuestion
	 */
	function AudioImageMultipleChoiceQuestionHtmlView() {

		/**
		 * Retrieve the html string for this MultipleChoiceQuestion
		 * @param {MultipleChoiceQuestion}
		 * @return {String} the generated html for the question
		 */
		this.getViewForModel = function(model) {
			
			var html = [];
			html.push("<div class='multipleChoiceQuestion'>");
			html.push("<img src=\"" + model.imageUrl + "\" />");
			html.push("</div>");

			// either render them as inline-block span elements or vertical div elements
			var containerTag = (model.displayDirection === 'vertical') ? 'div' : 'span';

			// radio buttons for single select, or checkboxes for multi select.
			var inputType = (model.checkboxRadio === 'radio') ? 'radio' : 'checkbox';
			gaerdvark.utils.forEachArray(model.answers, function(answer) {
				html.push("<" + containerTag +" class='multipleChoiceAnswer'>");
				html.push("<label>");
				html.push("<input type='" + inputType + "' name='" + model.id + "-"
					+ model.answerSetIdx + "' data-answersetidx='" + model.answerSetIdx
					+ "' value='" + answer.index + "' />");
				html.push(answer.answer);
				html.push("</label>");
				html.push("</" + containerTag +">");
			});
			return html.join("");
		};
	}
	
	module.AudioImageMultipleChoiceQuestionHtmlView = AudioImageMultipleChoiceQuestionHtmlView;
	
	return module;
})(gaerdvark.models.TestEditors || {}, gaerdvark.utils);