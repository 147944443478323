gaerdvark.models.TestEditors = (function(module, utils) {
	/**
	 * Used to verify whether a question is valid or not
	 */
	function QuestionValidator() {
		
		/**
		 * Checks to make sure that the passed in model has all of the required fields filled out
		 * @param {Question}
		 * @return {array} of Strings containing the specific error messages.  Empty array if there are no errors.
		 */
		this.validate = function(model) {
			if (!model instanceof module.Question) {
				throw new TypeError("Model must be an instance of type Question");
			}

			var errors = [];
			if (!utils.isValidString(model.html)) {
				errors.push("Html representation was not generated");
			}
			
			if (!utils.isValidString(model.question)) {
				errors.push("Missing question text");
			}
			
			return errors;
		};
	}
	
	module.QuestionValidator = QuestionValidator;

	return module;
})(gaerdvark.models.TestEditors || {}, gaerdvark.utils);