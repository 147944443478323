gaerdvark.models.TestEditors = (function(module, utils) {
	
	var testSettings = null;
	
	var settingsByHash = {};
	
	function TestSettings() {
		this.name = "";
		this.groups = [];
		this.instructorGradedRubric = "";
	}
	
	function TestSettingsLoader() {
		
		this.loadTestSettings = function(callback) {
			if (testSettings !== null) {
				callback(testSettings);
				return;
			}
			
			// for now while we are trying to figure out the data let's put in some dummy stuff
			// TODO: stephen this needs to be pulled from an action from the server...
			var settings = new TestSettings();
			settings.name = "IELTS";
			settings.showGradeId = true;
			settings.instructorGradedRubric = "IELTSGradingRubric";
			settings.subTestTypes = ["Reading", "Listening", "Speaking", "Writing"];
			testSettings = [settings];
			settingsByHash[settings.name] = settings;
			
			callback(testSettings);
		};
		
		this.getTestSettingsForTestType = function(testType) {
			if (settingsByHash.hasOwnProperty(testType)) {
				return settingsByHash[testType];
			}
			return null;
		};
		
		this.getTestSettings = function() {
			return testSettings;
		};
		
		/**
		 * Given the type of test, retrieve the array of types that a test group can have.
		 */
		this.getSubTypesForTestType = function(testType) {
			var groupTypes = [];
			
			if (settingsByHash.hasOwnProperty(testType)) {
				if (settingsByHash[testType].hasOwnProperty("subTestTypes")) {
					groupTypes = settingsByHash[testType]["subTestTypes"];
				}
			}
			return groupTypes;
		};
		
		this.getTestSettingLabels = function() {
			var options = [];
			if (testSettings !== null) {
				options = testSettings.map(function(item) {
					return item.name;
				});
			}
			return options;
		};
	}
	
	
	module.TestSettingsLoader = TestSettingsLoader;

	return module;
})(gaerdvark.models.TestEditors || {}, gaerdvark.utils);