gaerdvark.models.Game = (function(module) {
	
	/**
	 * Class definition holding the settings for the score metrics of a game and data used
	 * in saving a leaderboard.
	 * @returns
	 */
	function ScoringSettings() {
		
		/**
		 * Internal star array
		 * {StarSetting}
		 */
		var stars = [];
		
		/**
		 * Pointer to self
		 * {ScoringSettings}
		 */
		var settings = this;
		
		/**
		 * Holds the time and count for a star.
		 * 
		 * @param {int} count
		 * @param {int} time
		 */
		function StarSetting(count, time) {
			this.count = +count;
			this.time = +time;
		}
		
		/**
		 * Given a time, it will retrieve the number of stars that should be displayed for that time.
		 * 
		 * @param {int} time
		 */
		function getStarsForTime(time) {
			var length = stars.length;
			var starsAmount  = 0;
			for (var i = 0; i < length; i++)
			{
				if (time <= stars[i].time)
				{
					starsAmount = stars[i].count;
					break;
				}
			}
			return starsAmount;
		}
		
		/**
		 * Return the maximum number of stars a user can earn.
		 */
		function getMaxStars() {
			if (this.stars) {
				return this.stars.length;
			}
			return 0;
		}
		
		/**
		 * Sorting function that used by Array.sort will sort an array of StarSetting objects by lowest time.
		 * 
		 * @param {Date} a
		 * @param {Date} b
		 */
		function sortByTime(a, b)
		{
			return a.time - b.time;
		}
		
		/**
		 * Adds a star setting object to the settings object.
		 * 
		 * @param {int} starCount
		 * @param {int} time
		 */
		function addStarSetting(starCount, time)
		{
			stars.push(new StarSetting(starCount, time));
			stars.sort(sortByTime);
			settings.fastestTime = stars[0].time; // always will be the first one.
		}
		
		/**
		 * Public method to get stars for a time
		 * @param {Number} time
		 */
		this.getStarsForTime = getStarsForTime;
		
		/**
		 * Public method to add a star to the settings object.,
		 */
		this.addStarSetting = addStarSetting;
		
		/**
		 * Return the maximum number of stars that can be earned.
		 */
		this.getMaxStars = getMaxStars;
		
		/**
		 * The points amplitude that scoring should be based on.
		 */
		this.amplitude = 0;
		
		/**
		 * The title of the leaderboard
		 */
		this.leaderBoardTitle = "";
		
		/**
		 * The unique id of the leaderboard instance.
		 */
		this.leaderBoardId = "";
		
		/**
		 * The percentage that time should have in weighting the leaderboard
		 */
		this.timeToAccuracyWeighting = 100; // 100 percent on accuracy for now.
		
		/**
		 * The fastest star setting time that is considered a good score.  Users can still get faster times, but this is
		 * used as the baseline in point scoring purposes.
		 */
		this.fastestTime = 0;
		
		/**
		 * The id of the page that this tool / game is on.
		 */
		this.pageId = "";
	}
	
	/**
	 * Given an initial object populate the settings object that will be used to score
	 * the game.  Set up any leaderboard data as well.
	 * @param {Object} initialSettings 
	 */
	function createScoringSettings(initialSettings) {
		var settings = new module.ScoringSettings();
		
		if (!gaerdvark.utils.isEmpty(initialSettings))
		{
			settings.amplitude = +initialSettings.amplitude;
			settings.timeToAccuracyWeighting = +initialSettings.timetoAccuracy;
			
			if (!gaerdvark.utils.isEmpty(initialSettings.stars) && gaerdvark.utils.isArray(initialSettings.stars))
			{
				gaerdvark.utils.forEachArray(initialSettings.stars, function(star) {
					settings.addStarSetting(star.amount, star.time);
				});
			}
			
			if (typeof initialSettings.title === "string")
			{
				settings.leaderBoardTitle = initialSettings.title;
			}
			// sometimes a scoring settings object is passed in, instead of what
			// comes from the editor.
			else if (typeof initialSettings.leaderBoardTitle === "string") {
				settings.leaderBoardTitle = initialSettings.leaderBoardTitle;
			}
			
			if (typeof initialSettings.id === "string")
			{
				settings.leaderBoardId = initialSettings.id;
			}
			
			if (initialSettings.relatedId)
			{
				settings.pageId = initialSettings.relatedId; 
			}
		}
		
		// populate the settings from this piece.
		return settings;
	}
	
	module.createScoringSettings = createScoringSettings;
	module.ScoringSettings = ScoringSettings;
	return module;
})(gaerdvark.models.Game || {});