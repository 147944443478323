/* global gaerdvark, send, createSendObject */
gaerdvark.models.Game = (function(module) {
	
	/**
	 * Helper object that takes a leaderboard ranking and saves it up to the server.
	 */
	function LeaderboardRankingUpdater() {
		
		/**
		 * Given a GameScore and pageId save the leaderboard score to the
		 * server. If there is a callback provide it will call the callBack.
		 * 
		 * @param {GameScore}
		 *            score the score to save to the leaderboard
		 * @param {String}
		 *            courseId the courseId that the game is on so the Project
		 *            can be flagged as having a leaderboard.
		 * @param {String}
		 *            pageId the pageId that the game is on so the leaderboard
		 *            knows how to get back to that game.
		 * @param {Function}
		 *            callBack the call back to call when the leaderboard is
		 *            saved
		 * @param {ScoringSettings}
		 *            customSettings the settings to use in saving the
		 *            leaderboard.
		 */
		
		this.update = function(score, scoringSettings, courseId, relatedId, activityHash, gameType, callback) {
			var saveObject = new module.LeaderBoardDataSave();
			saveObject.instanceUuid = scoringSettings.leaderBoardId;
			saveObject.courseId = courseId;
			saveObject.relatedId = relatedId;
			saveObject.activityHash = activityHash;
			saveObject.title = scoringSettings.leaderBoardTitle;
			saveObject.gameType = gameType;
			saveObject.points = score.points;
			saveObject.milliseconds = score.time;
			saveObject.stars = score.stars;
			saveObject.maxStars = 5;
			if (score.maxStars) {
				saveObject.maxStars = score.maxStars;
			}
			if (!gaerdvark.utils.isEmpty(_SESSION.displayName)) {
				saveObject.userDisplayName = _SESSION.displayName;
			} else {
				saveObject.userDisplayName = "Guest";
			}
			saveObject.userAvatarUrl = _SESSION.avatarUrl;
			send(createSendObject("Save Leaderboard Entry", saveObject, callback));
		};
	}
	
	module.LeaderboardRankingUpdater = LeaderboardRankingUpdater;
	return module;
})(gaerdvark.models.Game || {});