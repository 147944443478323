gaerdvark.models.Game = (function(module) {	
	/**
	 * Model class for a game.  Tracks the current questions, deals with audio for the questions,
	 * and tracks the score / results of the game.
	 * @param {string} id to use for the game.
	 */
	function Game(id)
	{
		/**
		 * {Game}
		 */
		var _gamePtr = this;

		/**
		 * Timer object that keeps track of the stop, start times
		 * {GAETimer}
		 */
		var selectionTimer = null;

		/**
		 * The index for the current question
		 */
		var currentQuestionIndex = -1;

		/**
		 * The tool generator that creates our audios.
		 * {function}
		 */
		var audioPlayerFactory = null;

		/**
		 * Multimedia that is used for preloading audio files
		 * {Tool}
		 */
		var audioFileLoader = null;

		/**
		 * Multimedia that is used for playing the audio
		 * {Tool}
		 */
		var audioFilePlayer = null;

		/**
		 * Unique identifier for the game
		 */
		var gameId = (id !== undefined) ? id : gaerdvark.utils.uuid();

		/**
		 * Internal array that keeps track of game errors that occur that can be retrieved by the controller.
		 */
		var gameErrors = [];

		/**
		 * Internal flag that determines if all of the questions have finished playing and the game is over.
		 */
		var gameOver = false;

		/**
		 * Calculated result object for the game.
		 */
		var endGameResult = null;
		
		/**
		 * Two audio files are preloaded, we need to start swapping the audios once the first audio has been played.
		 */
		var hasPlayedFirstAudio = false;
		
		/**
		 * Internal flag used to determine if the game has been cleaned up.  A user may have cleaned up this game, but also
		 * have a pointer to the object in an async callback, which in this case we want them to be able to check if the game
		 * has been cleaned up.
		 */
		var isCleanedUp = false;
		
		/**
		 * Given an audio file object it will load that audio and when the file is ready to be played it will call the loadedCallback
		 * passing it the instantiated audio tool
		 * @param {AudioFile} audio object to load
		 * @param {function} loaded callback that will be passed the instantiated tool that will play the audio
		 */
		function loadAudio(audio, loadedCallback)
		{
			var loader = audioPlayerFactory(audio, function() {
				audio.loaded = true;
				if (typeof loadedCallback === 'function')
				{
					loadedCallback(loader);
				}
			});
		}
		
		/**
		 * Destroys an audio tool
		 */
		function destroyAudioTool(audioTool) {
			if (audioTool && audioTool.destruct)
			{
				audioTool.destruct();
				audioTool = null;
			}
		}

		/**
		 * Swaps the audio player and the audio loader so we can reuse the loader
		 */
		function swapAudioPlayers()
		{
			destroyAudioTool(audioFilePlayer);
			audioFilePlayer = audioFileLoader;
			audioFileLoader = null;
		}

		/**
		 * Increment our question index as well as our current question pointer.  Changes the status of the new question
		 * to module.STATUS_CURRENT
		 */
		function incrementQuestionPointer() {

			currentQuestionIndex++;
			if (_gamePtr.questions.hasOwnProperty(currentQuestionIndex))
			{
				_gamePtr.currentQuestion = _gamePtr.questions[currentQuestionIndex];
				_gamePtr.currentQuestion.status = module.STATUS_CURRENT;
			}
			else
			{
				gameOver = true;
				_gamePtr.currentQuestion = null;
			}
		}
		
		/************************
		 * Public Variables for the Model
		 ************************/

		/**
		 * Are the answers for the game shared across questions, or the same for every question
		 */
		this.shareChoicesForGame = false;

		/**
		 * Array of the questions
		 * {array} of Question objects
		 */
		this.questions = [];
		
		/**
		 * The data to use for the trophies of the game.
		 */
		this.trophyData = null;

		/**
		 * The current question that is being played
		 * {Question}
		 */
		this.currentQuestion = null;

		/**
		 * Number of milliseconds to delay when a user clicks the last answer
		 * before proceeding on to the results page.
		 * {number}
		 */
		this.finalAnswerDelay = 500;

		/**
		 * Default is Positive_Infinity which means everything.
		 */
		this.numberOfQuestionsToInclude = Number.POSITIVE_INFINITY;
		
		/**
		 * Whether to shuffle the questions or not.  Useful for debugging.
		 */
		this.shuffleQuestions = true;

		/**
		 * Boolean that indicates if the game has a leaderboard or not
		 */
		this.hasLeaderboard = false;
		
		/**
		 * The settings for scoring the game.
		 * {Game.ScoringSettings}
		 */
		this.scoringSettings = null;
		
		/**
		 * Scoring system for calculating a score.
		 */
		this.scorer = null;
		
		/************************
		 * Public Methods for the Model
		 ************************/

		/**
		 * Return the unique id for this game
		 */
		this.getId = function() {
			return gameId;
		};

		this.isGameOver = function() {
			return gameOver;
		};

		/**
		 * Set the factory function that will generate our audio tools.  Must return a tool that plays audio with
		 * stop, play, and destruct functions
		 * @param {function}
		 */
		this.setAudioFactory = function(factory) {
			audioPlayerFactory = factory;
		};

		/**
		 * Plays the audio file for the current question
		 */
		this.playCurrentQuestionAudio = function() {
			if (this.currentQuestion && this.currentQuestion.audio) {
				audioFilePlayer.play();
			}
		};

		/**
		 * Stops the audio file for the question currently playing
		 */
		this.stopCurrentQuestionAudio = function() {
			if (this.currentQuestion && this.currentQuestion.audio) {
				audioFilePlayer.stop();
			}
		};
		
		/**
		 * Allows an Audiofile to be loaded
		 */
		this.loadAudio = loadAudio;
		
		this.destroyAudioTool = destroyAudioTool;

		/**
		 * Will preload the first and second audio files so that the user has a better / faster ui experience when playing the game
		 * Calls the passed in callback once both audio files are ready to play.
		 * @param {function}
		 */
		this.preloadAudios = function(allAudiosLoadedCallback) {

			// grab all of the audio questions, as a question can be something other than an audio
			var audioQuestions = gaerdvark.utils.filterArray(this.questions, function(question) {
				return question.audio !== null;
			});
			
			var questionsLength = audioQuestions.length;
			var callBackFired = false;
			
			// if we have no audios... then just do the callback
			if (questionsLength < 1) {
				allAudiosLoadedCallback();
			}

			// don't do anything if we have exceeded our boundaries.
			if (questionsLength > 0)
			{
				var firstAudio = audioQuestions[0].audio;
				audioFilePlayer = audioPlayerFactory(firstAudio, function() {
					firstAudio.loaded = true;

					if (!callBackFired && secondAudio.loaded)
					{
						callBackFired = true;
						allAudiosLoadedCallback();
					}
				});
			}

			if (questionsLength > 1)
			{
				var secondAudio = audioQuestions[1].audio;
				audioFileLoader = audioPlayerFactory(secondAudio, function() {
					secondAudio.loaded = true;
					if (!callBackFired && firstAudio.loaded)
					{
						callBackFired = true;
						allAudiosLoadedCallback();
					}
				});
			}
			else {
				secondAudio = {loaded: true}; // let's it fill in as a fake object to make the logic easier
			}
		}


		/**
		 * Adds a question to the game model, setting it's status to be module.STATUS_INCOMPLETE
		 * @param {Question}
		 */
		this.addQuestion = function(question)
		{
			// set up any default answers as well.
			question.status = module.STATUS_INCOMPLETE;
			this.questions.push(question);
		};

		/**
		 * Retrieves the progress for all of the questions
		 * @return {array} of progress objects containing the following format { id: {string}, status: {string}, time: {TimeSpan} }
		 */
		this.getCurrentProgress = function() {
			return gaerdvark.utils.mapArray(this.questions, function(question) {

				var time = null;
				if (question.userAnswer !== null && question.userAnswer.time !== null)
				{
					time = question.userAnswer.time;
				}

				var progress = {
						id: question.id
						,status: question.status
						,time: time
				};
				return progress;
			});
		}

		/**
		 * Retrieves the results for the game.  Including the questionsCorrect, the totalTime, and results.
		 * @param return object with {results: {array of Question}, questionsCorrect: {number}, totalTime: {seconds}, hasLeaderBoard: {boolean},
		 * leaderBoardScore: {points: {Number}, stars: {Number}, time: {Number}} }
		 */
		this.getGameResults = function() {

			if (endGameResult !== null)
			{
				return endGameResult;
			}
			
			var questionsCorrect = 0;
			var totalTime = 0;
			var totalQuestions = this.questions.length;
			var score = null;
			var accuracy = 0;

			gaerdvark.utils.forEachArray(this.questions, function(question) {
				totalTime += question.userAnswer.time.getTimeInMilliseconds();
				questionsCorrect = question.userAnswer.isCorrect ? questionsCorrect + 1 : questionsCorrect;
			});

			if (this.hasLeaderboard) {
				accuracy = questionsCorrect / totalQuestions * 100;
				score = _gamePtr.scorer.calculateScore(accuracy, totalTime);
			}
			totalTime /= 1000; // get the time in seconds.

			endGameResult = {
				'results': this.questions
				,'questionsCorrect': questionsCorrect
				,'totalTime': totalTime.toFixed(1)
				,'hasLeaderboard': this.hasLeaderboard
				,'leaderBoardScore': score
			};

			return endGameResult;
		};


		/**
		 * Returns the choices for the current question that the user can select
		 * @return {array} of Answer
		 */
		this.getCurrentChoices = function() {

			if (this.currentQuestion != null)
			{
				return this.currentQuestion.answers;
			}

			return null;
		};

		/**
		 * Loads up the first question to be played.
		 */
		this.loadFirstQuestion = function() {
			incrementQuestionPointer();
		};


		/**
		 * Moves the game state to the next question. This will move the currentQuestion as well as start loading the audio of
		 * the subsequent question.
		 */
		this.nextQuestion = function() {
			
			// some Games can have text questions and audio questions interspersed
			// so we need to know when we are swapping the audio. since we preload two audios upfront 
			// we skip over the swapping if we have not played the first audio.  Once we play it
			// we swap the audio every time
			
			var audioWasSwapped = false;
			var questionHasAudio = this.currentQuestion && this.currentQuestion.audio;
			
			if (!hasPlayedFirstAudio && questionHasAudio) {
				hasPlayedFirstAudio = true;
			}
			
			// only swap the audio if we have actually played an audio question.
			if (hasPlayedFirstAudio && questionHasAudio) {
				swapAudioPlayers();
				audioWasSwapped = true;
			}
			
			incrementQuestionPointer();
			
			if (!this.isLastQuestion() && audioWasSwapped)
			{
				// now let's load the next audio.
				var audioQuestion = this.findNextAudioQuestionToLoad();
				if (audioQuestion && audioQuestion.audio && !audioQuestion.audio.loaded)
				{
					loadAudio(audioQuestion.audio, function(loader) {
						audioFileLoader = loader;
					});
				}
			}
		};
		
		/**
		 * From our current question index, scan through the subsequent questions and see
		 * if we can find an audio question.  Return that audio question
		 */
		this.findNextAudioQuestionToLoad = function() {
			var questions = this.questions;
			var questionSize = questions.length;
			for (var i = currentQuestionIndex + 1; i < questionSize; i++) {
				if (questions[i].audio && !questions[i].audio.loaded) {
					return questions[i];
				}
			}
			return null;
		};

		/**
		 * Given an id retrieves the question for that id
		 * @param {string}
		 * @return {Question}
		 */
		this.getQuestionForId = function(questionId) {
			var question = gaerdvark.utils.filterArray(this.questions, function(question) {
				return question.id === questionId;
			});
			if (question.length > 0)
			{
				return question[0];
			}
			return null;
		};

		/**
		 * Load the audio player for that question and call the callback function with that audio player
		 * @param {string}
		 * @param {function}
		 */
		this.loadAudioPlayerForQuestionId = function(questionId, loadedCallback)
		{
			var question = this.getQuestionForId(questionId);
			if (question !== null)
			{
				loadAudio(question.audio, loadedCallback);
			}
			else
			{
				throw new Error("Failed to find audio for question id " + questionId);
			}
		};

		/**
		 * Retrieve the choice for the given id
		 * @param {string}
		 * @return {Answer}
		 */
		this.getChoiceForId = function(choiceId)
		{
			if (this.currentQuestion === null)
			{
				return null;
			}

			var choice = gaerdvark.utils.filterArray(this.currentQuestion.answers, function(choice) {
				return choice.id === choiceId;
			});
			if (choice.length > 0)
			{
				return choice[0];
			}
			return null;
		}

		/**
		 * Given a choice grade the current question with the time taken to select that choice
		 * @param {string}
		 * @param {TimeSpan}
		 */
		this.gradeSelectedChoice = function(choiceId, timeTakenForAnswer)
		{
			if (this.currentQuestion === null)
			{
				throwError("Cannot grade question as currentQuestion is undefined");
				return;
			}

			var choice = this.getChoiceForId(choiceId);

			if (choice === null)
			{
				throw new Error("Choice was not found in data array for " + choiceId);
			}

			// create a new UserAnswer object
			var userAnswer = new module.UserAnswer();
			userAnswer.answer = choice;

			userAnswer.time = timeTakenForAnswer;
			userAnswer.formattedTime = timeTakenForAnswer.getFormattedTimeInSeconds(1);

			if (this.currentQuestion.correctAnswer.id === choiceId)
			{
				userAnswer.isCorrect = true;
				this.currentQuestion.status = module.STATUS_CORRECT;
			}
			else
			{
				userAnswer.isCorrect = false;
				this.currentQuestion.status = module.STATUS_INCORRECT;
			}
			this.currentQuestion.userAnswer = userAnswer;
		};

		/**
		 * Checks to see if the current question we are on is the last question.  Returns true if it is, false otherwise.
		 * @return {boolean}
		 */
		this.isLastQuestion = function() {
			return currentQuestionIndex >= (this.questions.length - 1);
		};
		
		/**
		 * Checks to see if the current question we are on is the first question to be presented. 
		 * @return {boolean}
		 */
		this.isFirstQuestion = function() {
			return currentQuestionIndex === 0;	
		};

		/**
		 * stop the timing of the current question
		 */
		this.stopTimer = function() {
			var timer = this.getTimer();
			if (timer !== null)
			{
				timer.stop(); // make sure everything's cleaned up
			}
		};
		
		/**
		 * Randomizes the questions if shuffling is enabled, and trims the questions to whatever numberOfQuestionsToInclude is set to.
		 */
		this.shuffleAndLimitQuestions = function() {
			var questions = this.questions;
			var numberOfQuestionsToInclude = this.numberOfQuestionsToInclude;
			
			if (this.shuffleQuestions) {
				questions = gaerdvark.utils.arrayShuffle(questions);
			}
			
			if (numberOfQuestionsToInclude > 0 && questions.length
					&& questions.length > numberOfQuestionsToInclude)
			{
				questions = questions.slice(0, numberOfQuestionsToInclude);
			}
			this.questions = questions;
		};
		
		/**
		 * Initialize the game, doing any initial data processing we need to have happen
		 */
		this.init = function() {
			this.shuffleAndLimitQuestions();
		};
		
		/**
		 * Start the game with the first question and begin any timers that are needed.
		 */
		this.startGame = function() {
			this.loadFirstQuestion();
			this.startTimer(); // start our timer
		};

		/**
		 * Get the time from the current clock
		 * @return {TimeSpan
		 */
		this.getTime = function() {
			var timer = this.getTimer();
			if (timer !== null)
			{
				return timer.getTimeSpan(); // 1 decimal places
			}
		};

		/**
		 * Start the timer for the current question
		 */
		this.startTimer = function() {
			var timer = this.getTimer();
			
			if (timer !== null)
			{
				this.stopTimer();

			}

			// instantiate the timer and start it
			timer = new gaerdvark.utils.GaeTimer();
			this.setTimer(timer);
			timer.start();
		};
		
		/**
		 * Set what our timer object should be for the game
		 * @param {gaerdvark.utils.GaeTimer}
		 */
		this.setTimer = function(timer) {
			selectionTimer = timer;
		};
		
		/**
		 * Return our timer object for the game
		 * @return {gaerdvark.utils.GaeTimer}
		 */
		this.getTimer = function(timer) {
			return selectionTimer;
		};
		

		/**
		 * Validates
		 */
		this.isGameValid = function() {
			gameErrors = [];

			// for now we only have one check, but we leave it generic
			if (!gaerdvark.utils.isArray(this.questions) || this.questions.length === 0)
			{
				gameErrors.push({message: "There are no audio files to play for this game", detailedError: "The questions array was empty or invalid"});
			}
			
			// go through and validate each question.
			gaerdvark.utils.forEachArray(this.questions, function(question) {
				var errors = question.validate();
				if (errors.length) {
					gameErrors = gameErrors.concat(errors);
				}
			});

			return gameErrors.length === 0;
		};
		
		/**
		 * Return the trophy data for this game.
		 */
		this.getTrophyData = function() {
			return this.trophyData;
		};
		
		/**
		 * If the user has earned a trophy, it is returned by this function. Otherwise null is returned
		 */
		this.getTrophyDataForSave = function(score) {
			
			var trophyScorer = new gaerdvark.models.TrophyScorer(this.getTrophyData());
			var earnedTrophy = trophyScorer.getEarnedTrophyForScore(score.points, 'points');
			return earnedTrophy;
		};

		/**
		 * Return the errors that were generated by the game validation
		 */
		this.getErrors = function() {
			return gameErrors;
		};

		/**
		 * Cleanup our audio files
		 */
		this.cleanup = function() {

			isCleanedUp = true;
			
			destroyAudioTool(audioFileLoader);

			destroyAudioTool(audioFilePlayer);

			// if the timer is running stop it.
			this.stopTimer();
		};
		
		this.isGameCleanedUp = function () {
			return isCleanedUp;
		};
	}
	module.Game = Game;
	
	return module;
})(gaerdvark.models.Game || {});	