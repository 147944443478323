gaerdvark.models.TestEditors = (function(module, utils) {	
	/**
	 * Represents an Essay Question.  Inherits from Question
	 */
	function EssayQuestion(data) {
		if (!data) {
			data = {};
		}
		data.type = (data && utils.isValidString(data.type)) ? data.type : "Essay";

		var question = new module.Question(data);
		
		var parentGetData = question.getData.bind(question);

		/**
		 * The rubric to use for instructor grading this question
		 */
		question.gradingRubric = (data && utils.isValidString(data.gradingRubric)) ? data.gradingRubric : null;
		
		/**
		 * The specific rubric task this question belongs to.  Used for pulling the correct criteria,
		 * comments, advice, and grade information for this type of question.
		 */
		question.gradingRubricTask = (data && utils.isValidString(data.gradingRubricTask)) ? data.gradingRubricTask : null;;
		
		/**
		 * Is this question to be graded by an instructor.
		 */
		question.isInstructorGradable = false;
		
		if(data.hasOwnProperty("isInstructorGradable")){
			question.isInstructorGradable = data.isInstructorGradable;
		}
		
		question.getData = function(){
			var data = parentGetData();
			data.isInstructorGradable = question.isInstructorGradable;
			data.gradingRubric = question.gradingRubric;
			data.gradingRubricTask = question.gradingRubricTask;
			return data;
		};
		
		return question;
	}

	module.EssayQuestion = EssayQuestion;
	
	return module;
})(gaerdvark.models.TestEditors || {}, gaerdvark.utils);