gaerdvark.models.TestEditors = (function(module, utils) {
	
	/**
	* Represents one of the Test Common Questions in the test editors.
	*/
	function Question(data) {
		var _model = this;

		/**
		* What type of question is this
		* @type {String}
		*/
		this.type = (data && utils.isValidString(data.type)) ? data.type : "Question";

		/**
		* @type {String}
		*/
		this.id = (data && utils.isValidString(data.id)) ? data.id : gaerdvark.utils.uuid();

		/**
		* The feedback for a question when the answer is incorrect.
		* @type {String}
		*/
		this.negativeFeedback = (data && utils.isValidString(data.negativeFeedback)) ? data.negativeFeedback : "";

		/**
		 * The html view of the question that is saved off for the LMS.  Containing the question and it's answers
		 * @type {String}
		 */
		this.html = (data && utils.isValidString(data.html)) ? data.html : "";

		/**
		 * The actual question html
		 * @type {String}
		 */
		this.question = (data && utils.isValidString(data.question)) ? data.question : "";

		/**
		 * Boolean to show the feedback when the question is graded or not
		 * @type {boolean}
		 */
		this.showFeedback = (data && typeof data.showFeedback == 'boolean') ? data.showFeedback : false;

		/**
		 * The array of {Answer} objects for the question
		 * @type {array}
		 */
		this.answers = [];
		
		/**
		* Retrieve the a copy of the internal data.
		*/
		this.getData = function() {
			return {
				type: this.type,
				id: this.id,
				negativeFeedback: this.negativeFeedback,
				html: this.html,
				question: this.question,
				showFeedback: this.showFeedback,
				// grab the answers and stuff them inside an array for data compatibility.
				/*
				 * The answers are inside of an array because there are tools that have multiple sets of answers and so
				 * this has to correspond to the answerSetIdx of 0.
				 */
				answers: [utils.mapArray(this.answers, function(answer) { return utils.cloneObject(answer); })]
			};
		};
	}
	
	module.Question = Question;
	
	return module;
})(gaerdvark.models.TestEditors || {}, gaerdvark.utils);