gaerdvark.models.TestV2 = (function (module) {

    /**
	 * Holds internal model information for the test
	 * @returns
	 */
	function TestModel(sessionData) {
        this.testData = new module.TestModelData();
        this._SESSION = sessionData;
        this.uuid = gaerdvark.utils.getUniqueNodeId();
    }
    /**
     * Return the id of the currently logged in user.
     */
    TestModel.prototype.getUserId = function () {
        return this._SESSION.userID;
    };
    /**
     * Return the identifier for the test.
     */
    TestModel.prototype.getTestId = function () {
        return this.testData.id;
    };
    /**
     * Return the name of the test
     */
    TestModel.prototype.getName = function () {
        return this.testData.name;
    };
    /**
     * Checks to see if our current hash state is in preview mode.  If it is we return true
     * false otherwise.
     */
    TestModel.prototype.isPreview = function () {
        var hash = getHash();
        return hash.mode === 'preview';
    };
    /**
     * Setup the state with the data from the outside
     * Add any additional things we need for just the internals of our test
     * or model.
     *
     * @param {Object} initialData
     */
    TestModel.prototype.init = function (initialData) {
        if (!initialData) {
            throwError("Test instantiated with no data");
            return;
        }
        this.testData.id = initialData.id;
        if (initialData.name) {
            this.testData.name = initialData.name;
        }
        if (initialData.settings) {
            // cycle through our instance data and see if those have been set
            var settings = initialData.settings;
            for (var optionKey in settings) {
                if (this.testData.settings.hasOwnProperty(optionKey)) {
                    this.testData.settings[optionKey] = settings[optionKey];
                }
            }
            if (!(this.testData.settings.testMultiplier > 0)) {
                this.testData.settings.testMultiplier = 1;
            }
            
        }
        if (initialData.groups) {
            this.testData.groups = initialData.groups;
        }
    };
    /**
     * Short hand for returning the test settings
     */
    TestModel.prototype.getSettings = function () {
        return this.testData.settings;
    };
    /**
     * Retrieve the model data.
     */
    TestModel.prototype.getData = function () {
        // any formatting that needs to go here?
        return this.testData;
    };
    TestModel.prototype.getTestTime = function () {
        if (this.testData.settings.time) {
            return this.testData.settings.time;
        }
        return 0;
    };
    /**
     * Return the type of test this is
     */
    TestModel.prototype.getTestType = function () {
        return this.testData.settings.testType;
    };
    /**
     * Return the test sub type if there is one
     */
    TestModel.prototype.getTestSubType = function () {
        return this.testData.settings.testSubType;
    };
    /**
     * Return the test sub type if there is one
     */
    TestModel.prototype.getGradeId = function () {
        return this.testData.settings.gradeId;
    };
    TestModel.prototype.setServerScore = function (correct, outOf) {
        this.testData.correct = +correct;
        this.testData.outOf = +outOf;
    };
    TestModel.prototype.getServerScore = function () {
        return { correct: this.testData.correct, outOf: this.testData.outOf };
    };
    TestModel.prototype.shouldShowClearTestButton = function () {
        return this.testData.settings.showClearTestButton;
    };
    TestModel.prototype.shouldSubmitBeforeContinue = function () {
        return this.testData.settings.submitBeforeContinue;
    };
    TestModel.prototype.shouldShowRetakeTestButton = function () {
        return this.testData.settings.showRetakeTestButton;
    };
    TestModel.prototype.shouldHideAllFeedback = function () {
        return this.testData.settings.hideAllFeedback;
    };
	
	module.TestModel = TestModel;
    return module;
})(gaerdvark.models.TestV2 || {});