gaerdvark.models.Game = (function(module) {
	
	/**
	 * Container for the progress icons in the game.
	 */
	function ProgressIcons() {
		
		/**
		 * Icons that represents a user's progress through the game and their results from their actions
		 */
		var progressIcons = {};
		progressIcons[module.STATUS_CORRECT] = '';
		progressIcons[module.STATUS_INCORRECT] = '';
		progressIcons[module.STATUS_CURRENT] = '';
		progressIcons[module.STATUS_INCOMPLETE] = '';
		
		this.setIcon = function(status, icon) {
			if (progressIcons.hasOwnProperty(status))
			{
				progressIcons[status] = icon;
			}
			else {
				throwError("Status not found of " + status);
			}
		};
		
		/**
		 * Given a status for a question it will return the appropriate icon for that status.  If the status does not exist
		 * it returns the incomplete icon.
		 * @param {string} status
		 */
		this.getIconForStatus = function(status)
		{
			if (progressIcons.hasOwnProperty(status))
			{
				return progressIcons[status];
			}
			else
			{
				throwError("Failed to find status in icons array " + status);
				return progressIcons[module.STATUS_INCOMPLETE];
			}
		};
		
		/**
		 * returns the images that need to be preloaded for the progress icons.
		 * @return Array of {GameImage}
		 */
		this.getImagesToLoad = function() {
			var progressKeys = Object.keys(progressIcons);
			var progressImages = gaerdvark.utils.mapArray(progressKeys, function(index) {
					return new module.GameImage(progressIcons[index], index);
			});
			return progressImages;
		};
	}
	
	module.ProgressIcons = ProgressIcons;
	
	return module;
})(gaerdvark.models.Game || {});