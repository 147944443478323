gaerdvark.models.TestEditors = (function(module, utils) {	
	/**
	 * Used to grab the html representation of a EssayQuestion
	 */
	function EssayHtmlView() {
		this.getViewForModel = function(model) {
			
			var html = [model.question];
			html.push('<textarea class="essayTextArea" data-answersetidx="0" name="' + model.id + '-0' + '"></textarea>');
			return html.join("");
		};
	}

	module.EssayHtmlView = EssayHtmlView;
	
	return module;
})(gaerdvark.models.TestEditors || {}, gaerdvark.utils);