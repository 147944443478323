gaerdvark.models.Container = (function(module, Tool) {	

	
	/**
	 * Container used in the view to display a bunch of information.
	 * 
	 * @param {String} id
	 * @param {Node} initContainer
	 * @returns {gaerdvark.tools.SoundMatchGame.Container}
	 */
	function Container(id, initContainer)
	{
		/**
		 * the pixels of where to absolutely place the container in reference to it's container parent
		 * {number}
		 */
		this.top = initContainer && initContainer.top ? initContainer.top : 0;

		/**
		 * the pixels of where to absolutely place the container in reference to it's container parent
		 * {number}
		 */
		this.left = initContainer && initContainer.left ? initContainer.left : 0;

		/**
		 * {string} the html content for the container
		 */
		this.content = initContainer && initContainer.content ? initContainer.content : '';

		/**
		 * {number}
		 */
		this.height = initContainer && initContainer.height ? initContainer.height : null;

		/**
		 * {number}
		 */
		this.width = initContainer && initContainer.width ? initContainer.width : null;

		/**
		 * {string}
		 */
		this.id = id ? id : gaerdvark.utils.uuid();
		
		/**
		 * {string}
		 */
		this.className = initContainer && initContainer.className ? initContainer.className : "";

		/**
		 * {DOMHtmlElement}
		 */
		this.node = null; // the node where the container will be placed.

		// stuff the contentBox data into the content
		if (initContainer && !gaerdvark.utils.isEmpty(initContainer.contentBoxData))
		{
			this.content = gaerdvark.utils.ToolUtils.convertContentBoxDataToString(initContainer.contentBoxData);
		}
	}

	return Container;
	
})(gaerdvark.models.Container || {}, Tool);	