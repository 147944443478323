gaerdvark.models.TestV2 = (function(module) {
	
	 function TestGroupGrader() {
        /**
         * Forces the groups to grade themselves (including all of their questions)
         */
        this.gradeAll = function (groups, gradeFinishedCallback) {
            this.gradeGroup('gradeAll', groups, gradeFinishedCallback);
        };
    }

    TestGroupGrader.prototype.gradeGroup = function (gradeFunction, groups, gradeFinishedCallback) {
        var gradeFinishedCallbackCalled = false;
        // because the grade is asynchronous we need to handle that scenario
        var groupGradeFinishedCallback = function () {
            // because groups can be graded asynchronously they have the possibility of being graded
            // ie all of the items have been graded but they have not yet been able to notify us
            // as we don't want to be calling our grade finished callback multiple times we stop it
            // from happening here.  This also is safer in case the group accidently calls the callback
            // multiple times and we end up sending many requests to the server.
            if (gradeFinishedCallbackCalled) {
                return;
            }
            
            var isGraded = gaerdvark.utils.everyArray(groups, function (group) {
                return group.isReadyForSave();
            });
            
            if (isGraded) {
                gradeFinishedCallback();
                // do we want to set the flag here?  If the prior function fails
                // we could get another callback which attempts to save data.
                gradeFinishedCallbackCalled = true;
            }
        };
        // pass on the grade to each sub tool.
        gaerdvark.utils.forEachArray(groups, function (group) {
            group[gradeFunction](groupGradeFinishedCallback);
        });
    };
    /**
     * Tells the group to grade itself',
     */
    TestGroupGrader.prototype.grade = function (groups, gradeFinishedCallback) {
        this.gradeGroup('grade', groups, gradeFinishedCallback);
    };
	
	module.TestGroupGrader = TestGroupGrader;
    return module;
})(gaerdvark.models.TestV2 || {});