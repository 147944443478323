gaerdvark.models.Game = (function(module, actions) {	
	/**
	 * Simple view that shows whatever html is sent in with the provided data and actions
	 * @param {object} the data to use for the view
	 * @param {string} The html of the view.
	 * @param {object} Hash map of function callbacks the view can call.
	 */
	function BasicView(viewData, html, actions) {

		/**
		 * The node that contains this view.
		 */
		this.node = null;

		/**
		 * Process html object that was used to produce this view.
		 * {ProcessHtml}
		 */
		this.htmlProcessor = null;

		/**
		 * Return the html that represents this view
		 * @return {string}
		 */
		this.getHtml = function() {
			return html;
		};

		/**
		 * Return the data to be used in ProcessHtml for the data flag replacements
		 * @return {object}
		 */
		this.getData = function() {
			return viewData;
		};
	}
	module.BasicView = BasicView;
	
	return module;
})(gaerdvark.models.Game || {});