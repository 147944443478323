/**
	 * Trophy Activity Instance used to represent a collection
	 * of trophies for an activity
	 * @returns {gaerdvark.models.TrophyActivityInstance}
	 */
gaerdvark.models.TrophyScorer = function(trophyData) {
	
	/**
	 * Returns true if this game has trophies;
	 */
	this.hasTrophies = function() {
		return trophyData != null && trophyData.trophies && trophyData.trophies.length;
	};
	
	/**
	 * Given the score of an activity, it returns the earned trophy that should be used
	 * for that score. If no trophy was earned or there are no trophies then null is returned.
	 * @param {score} the score to use to evaluate the trophy data
	 * @param {string} The type of criteria to use for determining the score
	 * @return {Null|EarnedTrophy} 
	 */
	this.getEarnedTrophyForScore = function(score, criteriaType) {
		var earnedTrophyData = null;
		
		if (!this.hasTrophies()) {
			return earnedTrophyData;
		}
		var numOfTrophys = trophyData.trophies.length;
		
		var earnedTrophy = null;
		for(var i = 0; i < numOfTrophys; i++){
			var trophy = trophyData.trophies[i];
			if (trophy.criteria.type !== criteriaType) {
				continue;
			}
			
			if(score >= trophy.criteria.value){
				earnedTrophy = trophy;
			}
		}
		
		if (earnedTrophy) {
			earnedTrophyData = new gaerdvark.models.EarnedTrophy();
			earnedTrophyData.trophyEarnedId = earnedTrophy.id;
			earnedTrophyData.trophyInstanceId = trophyData.trophyInstanceId;
			earnedTrophyData.levels = trophyData.trophies;
		}
		
		return earnedTrophyData;
	};
};