gaerdvark.models.TestEditors = (function(module, utils) {
	
	/**
	 * Represents a True False question in the system. Inherits from MultipleChoiceQuestion
	 */
	function TrueFalseQuestion(data) {
		var _model = this;

		if (!data) {
			data = {};
		}
		data.type = (data && utils.isValidString(data.type)) ? data.type : "True False";
		data.checkboxRadio = (data && utils.isValidString(data.checkboxRadio)) ? data.checkboxRadio : "radio";
		data.displayDirection = (data && utils.isValidString(data.displayDirection)) ? data.displayDirection : "vertical";
		var question = new module.MultipleChoiceQuestion(data);
		
		return question;
	}
	
	module.TrueFalseQuestion = TrueFalseQuestion;
	
	return module;
})(gaerdvark.models.TestEditors || {}, gaerdvark.utils);