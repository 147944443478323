gaerdvark.models.Game = (function(module) {
	/**
	 * Represents an image in the game
	 * @param {String} url
	 * @param {String} name
	 * @returns {gaerdvark.tools.SoundMatchGame.GameImage}
	 */
	function GameImage(url, name)
	{
		/**
		 * {string} url to the image
		 */
		this.url = url;

		/**
		 * Is the image loaded or not
		 */
		this.loaded = false;

		/**
		 * The name of the image.
		 */
		this.name = name;
	}
	module.GameImage = GameImage;
	
	return module;
})(gaerdvark.models.Game || {});